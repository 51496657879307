import commonReducer from './common/common/commonState';
import conditionReducer from './common/condition/conditionState';
import selectReducer from './common/select/selectState';
import loginReducer from './login/loginState';
import homeReducer from './home/homeState';

import contactListReducer from './opportunity/contact/contactListState';
import contactDetailReducer from './opportunity/contact/contactDetailState';
import opportunityListReducer from './opportunity/opportunity/opportunityListState';
import opportunityDetailReducer from './opportunity/opportunity/opportunityDetailState';
import opportunityAttachReducer from './opportunity/opportunity/opportunityAttachState';
import opportunityFileReducer from './opportunity/opportunityFile/opportunityFileState';
import estimateReducer from './opportunity/estimate/estimateState';
import estimateDetailReducer from './opportunity/estimate/estimateDetailState';
import orderReducer from './opportunity/order/orderListState';
import orderDetailReducer from './opportunity/order/orderDetailState';
import claimReducer from './opportunity/claim/claimState';
import claimDetailReducer from './opportunity/claim/claimDetailState';
import customerReducer from './opportunity/customer/customerState';
import customerPointHistoryReducer from './opportunity/customer/customerPointHistoryState';
import customerViewFavoriteReducer from './opportunity/customer/customerViewFavoriteState';

import accountReducer from './shop/account/accountState';
import accountUserReducer from './shop/accountUser/accountUserState';
import shopReducer from './shop/shop/shopState';
import shopDetailReducer from './shop/shop/shopDetailState';
import shopNoticeReducer from './shop/shopNotice/shopNoticeState';
import planReducer from './shop/plan/planState';
import planAttachedReducer from './shop/plan/planAttachedState';
import vendorListReducer from './shop/vendor/vendorListState';
import vendorDetailReducer from './shop/vendor/vendorDetailState';
import deliveryAgencyReducer from './shop/deliveryAgency/deliveryAgencyState';
import deliveryAgencyUserReducer from './shop/deliveryAgencyUser/deliveryAgencyUserState';

import reviewReducer from './pageSetting/review/reviewState';
import illustrationReducer from './pageSetting/illustration/illustrationState';
import specialFeatureReducer from './pageSetting/specialFeature/specialFeatureState';
import specialFeatureAttachedReducer from './pageSetting/specialFeature/specialFeatureAttachedState';
import blogReducer from './pageSetting/blog/blogState';
import blogContentReducer from './pageSetting/blog/blogContentState';

import roleListReducer from './systemSetting/role/roleListState';
import roleDetailReducer from './systemSetting/role/roleDetailState';
import loginUserReducer from './systemSetting/loginUser/loginUserState';
import pointRuleReducer from './systemSetting/pointRule/pointRuleState';
import mailTemplateReducer from './systemSetting/mailTemplate/mailTemplateState';
import trashReducer from './systemSetting/trash/trashState';
import claimAttachReducer from './opportunity/claim/claimAttachState';

const rootReducer = {
  commonStore: commonReducer,
  conditionStore: conditionReducer,
  selectStore: selectReducer,
  loginStore: loginReducer,
  homeStore: homeReducer,

  contactListStore: contactListReducer,
  contactDetailStore: contactDetailReducer,
  opportunityListStore: opportunityListReducer,
  opportunityDetailStore: opportunityDetailReducer,
  opportunityAttachStore: opportunityAttachReducer,
  opportunityFileStore: opportunityFileReducer,
  estimateStore: estimateReducer,
  estimateDetailStore: estimateDetailReducer,
  orderStore: orderReducer,
  orderDetailStore: orderDetailReducer,
  claimStore: claimReducer,
  claimDetailStore: claimDetailReducer,
  claimAttachStore: claimAttachReducer,
  customerStore: customerReducer,
  customerPointHistoryStore: customerPointHistoryReducer,
  customerViewFavoriteStore: customerViewFavoriteReducer,

  accountStore: accountReducer,
  accountUserStore: accountUserReducer,
  shopStore: shopReducer,
  shopDetailStore: shopDetailReducer,
  shopNoticeStore: shopNoticeReducer,
  planStore: planReducer,
  planAttachedStore: planAttachedReducer,
  vendorListStore: vendorListReducer,
  vendorDetailStore: vendorDetailReducer,
  deliveryAgencyStore: deliveryAgencyReducer,
  deliveryAgencyUserStore: deliveryAgencyUserReducer,

  reviewStore: reviewReducer,
  illustrationStore: illustrationReducer,
  specialFeatureStore: specialFeatureReducer,
  specialFeatureAttachedStore: specialFeatureAttachedReducer,
  blogStore: blogReducer,
  blogContentStore: blogContentReducer,

  roleListStore: roleListReducer,
  roleDetailStore: roleDetailReducer,
  loginUserStore: loginUserReducer,
  pointRuleStore: pointRuleReducer,
  mailTemplateStore: mailTemplateReducer,
  trashStore: trashReducer,
};

export default rootReducer;
