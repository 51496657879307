import React, { useState } from 'react';
import {
  TableCell, IconButton, Menu, MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PcTableWithMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    row, headCells, isNotEdit, isNotDelete,
    onDelete, onEdit, openedMenuRowId,
    setOpenedMenuRowId, setOpenDelete,
  } = props;

  const handleClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setOpenedMenuRowId(rowId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedMenuRowId(null);
  };

  return (
    <>
      {headCells.map((headCell) => {
        let cmp = (<TableCell style={{ padding: '0px 10px', whiteSpace: 'nowrap' }} key={headCell.id}>{row[headCell.id]}</TableCell>);
        if (headCell.numeric) {
          let value = row[headCell.id];
          if (value && typeof value === 'number' && !Number.isNaN(value)) {
            value = value.toLocaleString();
          }
          cmp = (<TableCell style={{ padding: '0px 10px', whiteSpace: 'nowrap' }} key={headCell.id}>{value}</TableCell>);
        }
        return (
          cmp
        );
      })}
      {((onDelete && !isNotDelete) || (onEdit && !isNotEdit)) && (
        <TableCell style={{
          padding: '0px',
          position: 'sticky',
          right: '0',
          backgroundColor: 'white',
        }}
        >
          {(((onDelete && row.deleteable && !isNotDelete) || (onEdit && !isNotEdit))) && (
            <IconButton onClick={(event) => handleClick(event, row.id, row)}>
              <ExpandMoreIcon size="small" />
            </IconButton>
          )}

          <Menu
            anchorEl={anchorEl}
            open={openedMenuRowId === row.id}
            onClose={handleClose}
          >
            {(onEdit && !isNotEdit) && (
              <MenuItem
                onClick={(e) => {
                  onEdit(e, openedMenuRowId, row);
                  setOpenedMenuRowId(null);
                }}
              >
                編集
              </MenuItem>
            )}
            {(onDelete && row.deleteable && !isNotDelete) && (
              <MenuItem
                onClick={() => { setOpenDelete(true); }}
              >
                削除
              </MenuItem>
            )}
          </Menu>
        </TableCell>
      )}
    </>
  );
}
