import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  DialogActions, CircularProgress, Box,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '../atoms/Dialog';
import TransferList from '../atoms/TransferList';
import { DialogContent } from '../atoms/Base';

export default function DownloadModal(props) {
  const {
    csvDatas, csvItem, detailCsvItem, open, complete, onDownload, onClose,
  } = props;
  const [showDetails, setShowDetails] = useState(false);
  const [right, setRight] = useState([]);
  const [left, setLeft] = useState([]);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (open) {
      setRight(csvItem);
      setLeft([]);
      setDone(false);
      setLoading(false);
      setShowDetails(false);
    }
  }, [open, csvItem]);

  useEffect(() => {
    if (complete) {
      setLoading(false);
      setDone(true);
    }
  }, [complete]);

  const onSetValue = (r, l, shoDetail) => {
    setRight(r);
    setLeft(l);
    setShowDetails(shoDetail); // 更新されたshowDetailsを設定
  };

  const onGetData = () => {
    setLoading(true);
    onDownload(showDetails);
  };

  return (
    <Dialog isOpen={open} onClose={onClose} maxWidth="lg" fullWidth title="CSV出力">
      <DialogContent>
        {(!done && !loading) && (
          <TransferList
            right={right}
            left={left}
            leftTitle="非出力項目"
            rightTitle="出力項目"
            detailCsvItem={detailCsvItem}
            onSetValue={onSetValue}
          />
        )}
        {loading && (
          <Box textAlign="center">
            <CircularProgress />
            <Box mt={3}>出力の準備をしています。このまましばらくお待ちください。</Box>
          </Box>
        )}
        {done && (
          <Box>
            <Box mb={3}>出力の準備が整いました。</Box>
            <CSVLink
              data={csvDatas.datas}
              headers={right}
              filename={`${csvDatas.fileName}.csv`}
            >
              ダウンロードする
            </CSVLink>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={onClose}>{done ? '閉じる' : 'キャンセル' }</LoadingButton>
        {!done && (
          <LoadingButton
            onClick={onGetData}
            loading={loading}
            variant="contained"
            disabled={right.length === 0}
          >
            出力する
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
