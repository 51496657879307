import React from 'react';
import {
  FormControlLabel, Radio,
} from '@mui/material';
import { FormRadioGroup } from '../../../components/atoms/Base';

const options = [
  { id: 1, name: 'リンク' },
  { id: 2, name: '画像' },
  { id: 3, name: 'テキスト' },
  { id: 4, name: '関連幹事のコツ' },
  { id: 5, name: '目次' },
];

export default function ContentTypeRadio(props) {
  const {
    error,
    value,
    onChange,
  } = props;

  return (
    <FormRadioGroup
      row
      value={value}
      name="contentType"
      onChange={onChange}
      defaultValue={1}
      error={error}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.id}
          value={option.id}
          control={<Radio />}
          label={option.name}
        />
      ))}
    </FormRadioGroup>
  );
}
