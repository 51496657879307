import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTableWithMenu from '../../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { SuccessSnackbar } from '../../../../components/atoms/Base';
import detailUrl from '../../../../constants/frontUrls';
import { planActions } from '../../../../redux/shop/plan/planState';
import { isScreenDeleteDisabledFromUrl } from '../../../../utils/authCheck.helper';

const HEAD_CELLS = [
  { id: 'name', label: 'プラン名', sortId: 'name' },
  { id: 'serviceTypeName', label: 'サービスタイプ', sortId: 'service_type_name' },
  {
    id: 'itemNumber', label: '品目数', sortId: 'item_number', numeric: true,
  },
  {
    id: 'price', label: '価格', sortId: 'price', numeric: true,
  },
  { id: 'published', label: '公開', sortId: 'published' },
];

export default function PlanDataTable(props) {
  const { shopId } = props;
  const dispatch = useDispatch();
  const dbPlans = useSelector((state) => state.planStore.datas);
  const isSaveSuccess = useSelector((state) => state.planStore.isSaveSuccess);
  const isDeleteSuccess = useSelector((state) => state.planStore.isDeleteSuccess);
  const [plans, setPlans] = React.useState({
    content: [],
    totalElements: 0,
  });
  const [searchCondition, setSearchCondition] = React.useState({
    records: 50,
    page: 0,
    order: 'asc',
    orderBy: '',
    shopId,
  });
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const isNotDelete = useSelector((state) => isScreenDeleteDisabledFromUrl(state.loginStore.userInfo, '/plan'));

  const search = () => {
    dispatch(planActions.getDatas(searchCondition));
  };

  React.useEffect(() => {
    if (shopId) {
      search();
    }
  }, [shopId]);
  React.useEffect(() => {
    if (dbPlans) {
      setPlans(dbPlans);
    }
  }, [dbPlans]);
  React.useEffect(() => {
    if (isSaveSuccess) {
      search();
      setSuccessOpen(true);
      setSuccessMessage('保存しました');
      dispatch(planActions.resetFlg());
    }
  }, [isSaveSuccess]);
  React.useEffect(() => {
    if (isDeleteSuccess) {
      search();
      setSuccessOpen(true);
      setSuccessMessage('削除しました');
      dispatch(planActions.resetFlg());
    }
  }, [isDeleteSuccess]);

  const onChangePage = (_, newPage) => {
    setSearchCondition({
      ...searchCondition,
      page: newPage,
    });
    search();
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setSearchCondition({
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    });
    search();
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    setSearchCondition({
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    });
    search();
  };

  const onDelete = (openedMenuRowId) => {
    dispatch(planActions.deleteData({ ids: [openedMenuRowId] }));
  };

  const onSnackbarClose = () => {
    setSuccessOpen(false);
    setSuccessMessage('');
  };

  return (
    <>
      <DataTableWithMenu
        rows={plans.content || []}
        procName="プラン"
        headCells={HEAD_CELLS}
        totalElements={plans.totalElements}
        rowsPerPage={searchCondition.records}
        page={searchCondition.page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        url={detailUrl.PLAN_DETAIL}
        onDelete={onDelete}
        isNotDelete={isNotDelete}
        isNotAdd
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onSnackbarClose}
        message={successMessage}
      />
    </>
  );
}
