import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (name, value) => {
    switch (name) {
      case 'receptionStartTime':
      case 'receptionEndTime':
      case 'deliveryStartTime':
      case 'deliveryEndTime':
      case 'pickupOneStartTime':
      case 'pickupOneEndTime':
      case 'pickupTwoEndTime':
      case 'pickupTwoStartTime':
      case 'rsvDeadlineTime':
      case 'cateringDeliveryStartTime':
      case 'cateringDeliveryEndTime':
      case 'cateringPickupOneStartTime':
      case 'cateringPickupOneEndTime':
      case 'cateringPickupTwoStartTime':
      case 'cateringPickupTwoEndTime':
      case 'cateringRsvDeadlineTime':
        return CommonValidation.nonRequiredTime(value);
      case 'email':
        return CommonValidation.nonRequiredEmail(value);
      case 'postcode':
        return CommonValidation.nonRequiredPostCode(value);
      case 'publishDt':
        return CommonValidation.nonRequiredDate(value);
      case 'phone':
      case 'emergencyPhone':
      case 'cqreeReceptionPhone':
        return CommonValidation.nonRequiredPhoneNumber(value);
      default:
        return '';
    }
  };

  static optionFormValidate = (name, value) => {
    switch (name) {
      case 'name':
        return CommonValidation.requiredValue(value);
      case 'optionTypeCode':
      case 'taxRate':
      case 'minOptionAssignTypeCode':
      case 'maxOptionAssignTypeCode':
        return CommonValidation.requiredSelect(value);
      case 'price':
      case 'minOptionAssignValue':
      case 'maxOptionAssignValue':
        return CommonValidation.requiredNumber(value);
      case 'orderStartDt':
      case 'orderEndDt':
        return CommonValidation.nonRequiredDate(value);
      case 'publishDt':
        return CommonValidation.nonRequiredDate(value);
      default:
        return '';
    }
  };

  static deliveryFormValidate = (name, value) => {
    switch (name) {
      case 'name':
        return CommonValidation.requiredValue(value);
      case 'serviceTypeCode':
        return CommonValidation.requiredSelect(value);
      case 'minPrice':
        return CommonValidation.requiredNumber(value);
      default:
        return '';
    }
  };

  static holidayFormValidate = (name, value) => {
    switch (name) {
      case 'name':
        return CommonValidation.requiredValue(value);
      case 'serviceTypeCode':
      case 'holidayTypeCode':
        return CommonValidation.requiredSelect(value);
      case 'minPrice':
        return CommonValidation.requiredNumber(value);
      case 'startDate':
      case 'endDate':
        return CommonValidation.requiredDate(value);
      default:
        return '';
    }
  };

  static cancelRateValidate = (type, value) => {
    switch (type) {
      case 'chargeDays':
        return CommonValidation.requiredNotMinusNumber(value);
      case 'chargeRate':
        return CommonValidation.requiredNumber(value);
      case 'chargeTime':
        return CommonValidation.requiredTime(value);
      case 'serviceTypeCode':
        return CommonValidation.requiredSelect(value);
      case 'displayOrder':
        return CommonValidation.requiredValue(value);
      default:
        return '';
    }
  };
}

export default Validation;
