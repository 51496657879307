import React, { useState, useEffect } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function RecordHeader(props) {
  const { topSection, bottomSection } = props;
  const theme = useTheme();
  const [isTopSectionVisible, setIsTopSectionVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 95) {
        setIsTopSectionVisible(false);
      } else {
        setIsTopSectionVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'block',
        position: 'relative',
        marginBottom: 1,
        [theme.breakpoints.up('md')]: {
          marginBottom: 0,
          position: 'sticky',
          top: 90,
          zIndex: 1000,
          backgroundColor: 'white',
          display: 'flex',
        },
      }}
    >
      <Box
        sx={{
          flex: '1',
          mb: 1,
          display: 'block',
          transition: 'opacity 0.3s ease, visibility 0.3s ease',
          opacity: isTopSectionVisible ? 1 : 0,
          visibility: isTopSectionVisible ? 'visible' : 'hidden',
          height: 'auto',
          [theme.breakpoints.up('md')]: {
            display: 'flex',
          },
        }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1}
          sx={{ width: '100%' }}
        >
          {topSection}
        </Stack>
      </Box>
      <Box>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1}
          sx={{ width: '100%' }}
        >
          {bottomSection}
        </Stack>
      </Box>
    </Box>
  );
}

export function RecordHeaderButton(props) {
  const { edit, disabled, onEdit } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        minHeight: '30px',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
          position: 'sticky',
          top: 90,
          zIndex: 1000,
          display: 'flex',
        },
      }}
    >
      {!edit && !disabled && (
        <Button sx={{ width: { xs: '100%', md: '' } }} variant="contained" size="small" onClick={onEdit}>編集</Button>
      )}
    </Box>
  );
}
