import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Toolbar, Typography, Tooltip, Button,
  TableRow, TableCell, TableBody, Table,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import BlogDetailInfo from './BlogDetailInfo';
import BlogContentDialog from './BlogContentDialog';
import { SuccessSnackbar, withParams, TabPanel } from '../../../components/atoms/Base';
import { blogActions } from '../../../redux/pageSetting/blog/blogState';
import { blogContentActions } from '../../../redux/pageSetting/blog/blogContentState';
import BlogContent from './BlogContent';
import Tabs from '../../../components/templates/Tabs';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import DeleteDialog from '../../../components/templates/DeleteDialog';

class BlogDetail extends React.Component {
  constructor(props) {
    super(props);

    const { id } = this.props.params;
    if (id) {
      this.props.dispatch(blogActions.getData({ id }));
      this.props.dispatch(blogContentActions.getDatas({ id }));
    }
    let isSuccessOpen = false;
    let successMessage = '';
    if (props.blogInfo.isSaveSuccess) {
      isSuccessOpen = true;
      successMessage = '保存しました。';
      props.dispatch(blogActions.resetFlg());
    }

    this.state = {
      id,
      tabValue: 0,
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      contents: [],
      isOpenDialog: false,
      contentId: null,
      isOpenDeleteDialog: false,
      deleteContentId: '',
      isSuccessOpen,
      successMessage,
      isSort: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.blogContentInfo.datas !== this.props.blogContentInfo.datas) {
      if (this.props.blogContentInfo.datas) {
        this.setContents(this.props.blogContentInfo.datas);
      }
    }
    if (prevProps.blogContentInfo.isDeleteSuccess !== this.props.blogContentInfo.isDeleteSuccess) {
      if (this.props.blogContentInfo.isDeleteSuccess) {
        this.setSuccess('削除しました。');
      }
    }
    if (prevProps.blogContentInfo.isSaveSuccess !== this.props.blogContentInfo.isSaveSuccess) {
      if (this.props.blogContentInfo.isSaveSuccess) {
        this.setSuccess('保存しました。');
      }
    }
  }

  setSuccess = (successMessage) => {
    const { id } = this.state;
    this.props.dispatch(blogContentActions.resetFlg());
    this.setState({ isSuccessOpen: true, successMessage, deleteContentId: '' });
    this.props.dispatch(blogContentActions.getDatas({ id }));
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  onAdd = () => {
    this.setState({ isOpenDialog: true, contentId: '' });
  };

  onEdit = (contentId) => {
    this.setState({ isOpenDialog: true, contentId });
  };

  onClose = () => {
    this.setState({
      isOpenDialog: false,
      contentId: '',
      isOpenDeleteDialog: false,
      deleteContentId: '',
    });
  };

  onOpenDelete = (deleteContentId) => {
    this.setState({ isOpenDeleteDialog: true, deleteContentId });
  };

  onDeleteContent = () => {
    this.setState({ isOpenDeleteDialog: false });
    const { deleteContentId } = this.state;
    this.props.dispatch(blogContentActions.deleteDatas({ ids: [deleteContentId] }));
  };

  setContents = (contents) => {
    this.setState({ contents });
  };

  reorder = (startIndex, endIndex) => {
    const { contents } = this.state;
    const result = Array.from(contents);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }
    const arrays = this.reorder(
      result.source.index,
      result.destination.index,
    );
    const updatedArrays = arrays.map((obj, index) => ({
      ...obj,
      displayOrder: index + 1,
    }));
    this.setState({ contents: updatedArrays });
  };

  onSort = () => {
    this.setState({ isSort: true });
  };

  onSortEnd = () => {
    this.setState({ isSort: false });
  };

  render() {
    const {
      tabValue,
      contents,
      id,
      isDisabled,
      isOpenDialog,
      contentId,
      isOpenDeleteDialog,
      isSuccessOpen,
      successMessage,
      isSort,
    } = this.state;

    return (
      <>
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={id ? 5 : 12}>
            <BlogDetailInfo id={id} isDisabled={isDisabled} />
          </Grid>
          {id && (
            <Grid item xs={12} md={7}>
              <Tabs
                value={tabValue}
                onChange={this.onTabChange}
                tabs={['コンテンツ']}
              />
              <TabPanel value={tabValue} index={0}>
                <Toolbar style={{ paddingLeft: 0 }}>
                  <>
                    <Typography style={{ flex: '1 1 30%' }} color="inherit" variant="subtitle1" component="div" />
                    <Tooltip>
                      <>
                        {!isSort && (
                        <Button color="primary" variant="outlined" onClick={this.onSort} style={{ marginRight: '10px' }}>並び替え</Button>
                        )}
                        {isSort && (
                        <Button variant="contained" onClick={this.onSortEnd} style={{ marginRight: '10px' }}>完了</Button>
                        )}
                        <Button color="primary" variant="outlined" onClick={this.onAdd}>幹事のコツコンテンツの追加</Button>
                      </>
                    </Tooltip>
                  </>
                </Toolbar>
                {contents.length > 0 && !isSort && (
                  contents.map((content) => (
                    <BlogContent
                      key={content.id}
                      data={content}
                      onEdit={this.onEdit}
                      onDelete={this.onOpenDelete}
                    />
                  ))
                )}
                {contents.length > 0 && isSort && (
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="content">
                    {(provided) => (
                      <div
                        className="contents"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {contents.map((content, index) => (
                          <Draggable
                            key={content.id}
                            draggableId={content.id.toString()}
                            index={index}
                          >
                            {(p) => (
                              <div
                                className="content"
                                {...p.draggableProps}
                                {...p.dragHandleProps}
                                ref={p.innerRef}
                              >
                                <BlogContent
                                  data={content}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                )}
                {contents.length === 0 && (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }} colSpan={1}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                )}
              </TabPanel>
            </Grid>
          )}
          <BlogContentDialog
            isOpen={isOpenDialog}
            onClose={this.onClose}
            blogContentId={contentId}
            blogId={id}
          />
        </Grid>
        <DeleteDialog
          open={isOpenDeleteDialog}
          onClose={this.onClose}
          procName="幹事のコツコンテンツ"
          onDelete={this.onDeleteContent}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  blogInfo: state.blogStore,
  blogContentInfo: state.blogContentStore,
});

export default withParams(connect(mapStateToProps)(BlogDetail));
