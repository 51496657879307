import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Button,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { PDFViewer } from '@react-pdf/renderer';
import { upload } from '../../../../utils/common.helper';
import { REST_API } from '../../../../constants/serverUrls';
import { estimateActions } from '../../../../redux/opportunity/estimate/estimateState';
import { estimateDetailActions } from '../../../../redux/opportunity/estimate/estimateDetailState';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../../utils/authCheck.helper';
import EstimateDetailDialog from './EstimateDetailDialog';
import EstimateDetailInfo from './EstimateDetailInfo';
import ConfirmDialog from '../../../../components/templates/ConfirmDialog';
import EstimateFileDownloadButton from '../file/EstimateFileDownloadButton';
import EstimateFile from '../../report/EstimateFile';
import EstimateAffiliationFile from '../../report/EstimateAffiliationFile';
import EstimateEnglishFile from '../../report/EstimateEnglishFile';
import FileDataTable from '../../opportunityFile/FileDataTable';
import detailUrl from '../../../../constants/frontUrls';
import { withParams, SuccessSnackbar, TabPanel } from '../../../../components/atoms/Base';
import Tabs from '../../../../components/templates/Tabs';
import DetailTable from '../../common/detailTable/DetailTable';
import FullScreenMailer from '../../../../components/templates/mailer/FullScreenMailer';
import { opportunityFileActions } from '../../../../redux/opportunity/opportunityFile/opportunityFileState';
import HistoryData from './HistoryData';
import RecordHeader from '../../../../components/templates/RecordHeader';

const DEFAULT_MAILER_DATA = {
  to: '',
  cc: '',
  subject: '',
  body: '',
};

class EstimateDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    this.detailCells = [
      { id: 'planName', label: '商品名', sortId: 'plan_name' },
      {
        id: 'unitPrice', label: '税込単価', sortId: 'unit_price', numeric: true,
      },
      { id: 'quantity', label: '個数', sortId: 'quantity' },
      { id: 'taxRate', label: '税率', sortId: 'tax_rate' },
      {
        id: 'totalPrice', label: '合計金額', sortId: 'total_price', numeric: true,
      },
      { id: 'claimed', label: '請求対象', sortId: 'claimed' },
      { id: 'repaid', label: '返金対象', sortId: 'repaid' },
      { id: 'reportHidden', label: '帳票非表示', sortId: 'report_hidden' },
    ];

    const searchCondition = {
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: 'display_order',
      estimateId: id,
    };

    let isNewEstimate = false;
    if (!id) {
      isNewEstimate = true;
    }

    let successMessage = '';
    let isSuccessOpen = false;
    if (props.estimateInfo.isSaveSuccess) {
      props.dispatch(estimateActions.resetFlgs());
      successMessage = '保存しました。';
      isSuccessOpen = true;
    }

    this.state = {
      isNotEdit: isScreenEditDisabled(props.loginUser.userInfo),
      isNotDelete: isScreenDeleteDisabled(props.loginUser.userInfo),
      isNewEstimate,
      isOpen: false,
      isSuccessOpen,
      successMessage,
      isMailerOpen: false,
      estimate: {
        id,
        eightPercentTotalPrice: 0,
        eightPercentTotalTaxPrice: 0,
        eightPercentDiscountPrice: 0,
        tenPercentTotalPrice: 0,
        tenPercentTotalTaxPrice: 0,
        tenPercentDiscountPrice: 0,
        totalPrice: 0,
        discountTargetPrice: 0,
        customerBillPrice: 0,
        burdenPrice: 0,
        pointUsagePrice: 0,
        salesFeeBillPrice: 0,
        refundPrice: 0,
        commissionPrice: 0,
        destination: '',
      },
      datas: [],
      historiesRequest: {
        id: null,
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      },
      tabValue: 0,
      pdfData: {
        header: {},
        details: [],
      },
      searchCondition,
      fileMailerData: DEFAULT_MAILER_DATA,
      requestMailerData: DEFAULT_MAILER_DATA,
      openedMenuRowId: null,
      isSendEstimate: false,
      isCreateOrderConfirmOpen: false,
      loading: false,
    };
    if (id) {
      props.dispatch(estimateDetailActions.getDatas(searchCondition));
      props.dispatch(estimateActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.estimateDetailInfo.datas !== this.props.estimateDetailInfo.datas) {
      if (this.props.estimateDetailInfo.datas) {
        this.setDatas(this.props.estimateDetailInfo.datas);
      }
    }
    if (prevProps.estimateDetailInfo.files !== this.props.estimateDetailInfo.files) {
      if (this.props.estimateDetailInfo.files) {
        this.setFiles(this.props.estimateDetailInfo.files);
      }
    }
    if (prevProps.estimateInfo.data !== this.props.estimateInfo.data) {
      if (this.props.estimateInfo.data) {
        this.setData(this.props.estimateInfo.data);
      }
    }
    if (prevProps.estimateInfo.pdfData !== this.props.estimateInfo.pdfData) {
      if (this.props.estimateInfo.pdfData) {
        this.setPdfData(this.props.estimateInfo.pdfData);
      }
    }
    if (prevProps.estimateInfo.isSaveSuccess
      !== this.props.estimateInfo.isSaveSuccess) {
      if (this.props.estimateInfo.isSaveSuccess) {
        this.saveSuccess(this.props.estimateInfo.id);
      }
    }
    if (prevProps.estimateDetailInfo.isSaveSuccess
      !== this.props.estimateDetailInfo.isSaveSuccess) {
      if (this.props.estimateDetailInfo.isSaveSuccess) {
        this.saveDetailSuccess();
      }
    }
    if (prevProps.estimateDetailInfo.isBulkDataSuccess
      !== this.props.estimateDetailInfo.isBulkDataSuccess) {
      if (this.props.estimateDetailInfo.isBulkDataSuccess) {
        this.saveDetailSuccess();
      }
    }
    if (prevProps.estimateInfo.isCreateOrderSuccess
      !== this.props.estimateInfo.isCreateOrderSuccess) {
      if (this.props.estimateInfo.isCreateOrderSuccess) {
        this.createOrderSuccess(this.props.estimateInfo.orderId);
      }
    }
    if (prevProps.estimateDetailInfo.isDeleteSuccess
      !== this.props.estimateDetailInfo.isDeleteSuccess) {
      if (this.props.estimateDetailInfo.isDeleteSuccess) {
        this.deleteSuccess();
      }
    }
  }

  onDelete = (deleteId) => {
    this.props.dispatch(estimateDetailActions.deleteData({ ids: [deleteId] }));
  };

  saveSuccess = (id) => {
    const { estimate } = this.state;
    const searchId = id || estimate.id;
    if (estimate.id) {
      this.props.dispatch(estimateActions.resetFlgs());
    }
    if (!estimate.id) {
      window.location.href = detailUrl.ESTIMATE_DETAIL + searchId;
      return;
    }
    this.setState({
      isOpen: false,
      isSuccessOpen: true,
      successMessage: '保存しました。',
    });
    this.props.dispatch(estimateActions.getData({ id: searchId }));
  };

  saveDetailSuccess = () => {
    const { estimate, searchCondition } = this.state;
    this.props.dispatch(estimateActions.getData({ id: estimate.id }));
    const tempsearchCondition = {
      ...searchCondition,
      estimateId: estimate.id,
    };
    this.props.dispatch(estimateDetailActions.getDatas(tempsearchCondition));
    this.setState({
      isSuccessOpen: true,
      successMessage: '保存しました。',
      isOpen: false,
      tabValue: 0,
    });
  };

  deleteSuccess = () => {
    const { estimate, searchCondition } = this.state;
    this.props.dispatch(estimateActions.getData({ id: estimate.id }));
    const tempsearchCondition = {
      ...searchCondition,
      estimateId: estimate.id,
    };
    this.props.dispatch(estimateDetailActions.getDatas(tempsearchCondition));
    this.setState({
      isSuccessOpen: true, successMessage: '削除しました。',
    });
  };

  onAdd = () => {
    this.setState({ isOpen: true });
  };

  onEdit = (_, openedMenuRowId) => {
    this.setState({ openedMenuRowId, isOpen: true });
  };

  onSendEstimate = () => {
    const { estimate, fileMailerData } = this.state;
    this.setState({
      isSendEstimate: true,
      fileMailerData: {
        ...fileMailerData,
        to: estimate.shopEmail,
      },
    });
    this.props.dispatch(estimateActions.getPdfData({ id: estimate.id }));
  };

  setPdfData = (pdfData) => {
    const { isSendEstimate } = this.state;
    if (pdfData.header) {
      this.setState({ pdfData, isMailerOpen: true });
      if (isSendEstimate) {
        this.setState({ isMailerOpen: true });
      }
    }
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setData = (data) => {
    const { historiesRequest, requestMailerData } = this.state;
    const tempHistoriesRequest = {
      ...historiesRequest,
      id: data.id,
    };
    this.setState({
      estimate: data,
      historiesRequest: tempHistoriesRequest,
      requestMailerData: { ...requestMailerData, to: data.shopEmail },
    });
    this.props.dispatch(estimateDetailActions.getOutputHistories(tempHistoriesRequest));
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(estimateDetailActions.getDatas(searchCondition));
  };

  onClose = () => {
    this.setState({
      isOpen: false,
      isMailerOpen: false,
      isSendEstimate: false,
      openedMenuRowId: null,
      isCreateOrderConfirmOpen: false,
    });
  };

  onCreateOrder = () => {
    this.setState({ isCreateOrderConfirmOpen: true });
  };

  onCreate = () => {
    const { estimate } = this.state;
    this.props.dispatch(estimateActions.createOrder({ id: estimate.id }));
  };

  createOrderSuccess = (orderId) => {
    if (orderId) {
      window.location.href = detailUrl.ORDER_DETAIL + orderId;
    } else {
      this.props.dispatch(estimateActions.resetFlgs());
    }
  };

  onSendFile = async (data) => {
    this.setState({ loading: true });
    const { estimate, pdfData } = this.state;

    const file = (estimate.englishReport && <EstimateEnglishFile data={pdfData} />)
    || (estimate.affiliationId && <EstimateAffiliationFile data={pdfData} />)
    || <EstimateFile data={pdfData} />;

    const sendMeilerData = {
      ...data,
      id: estimate.id,
    };

    const result = await upload(
      file,
      sendMeilerData,
      REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_SEND_QUOTATION,
      pdfData.header.fileName,
    );
    this.setState({ loading: false });
    if (result == null) return;
    this.setState({
      isSuccessOpen: true, successMessage: '送信しました。', isMailerOpen: false, loading: false, isSendEstimate: false,
    });
    this.props.dispatch(opportunityFileActions.onReload());
    this.props.dispatch(estimateDetailActions.historyReload());
  };

  render() {
    const {
      isNewEstimate,
      estimate,
      isSuccessOpen,
      successMessage,
      isNotEdit,
      isNotDelete,
      datas,
      tabValue,
      isOpen,
      isMailerOpen,
      pdfData,
      fileMailerData,
      openedMenuRowId,
      isSendEstimate,
      isCreateOrderConfirmOpen,
      loading,
      searchCondition,
    } = this.state;

    return (
      <>
        <RecordHeader
          bottomSection={(
            <>
              {!isNotEdit && datas.length > 0 && (
                <Button variant="contained" size="small" onClick={this.onCreateOrder}>注文作成</Button>
              )}
              {datas.length > 0 && (
                <EstimateFileDownloadButton
                  id={estimate.id}
                  englishReport={estimate.englishReport}
                  affiliationId={estimate.affiliationId}
                />
              )}

              {datas.length > 0 && (
                <Button variant="contained" size="small" onClick={this.onSendEstimate} startIcon={<SendIcon />}>見積書送付</Button>
              )}
            </>
          )}
        />
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={estimate.id ? 5 : 12}>
            <EstimateDetailInfo
              id={estimate.id}
              data={estimate}
              isDisabled={isNotEdit}
            />
          </Grid>
          {!isNewEstimate && estimate.id && (
          <Grid item xs={12} md={7}>
            <Tabs
              value={tabValue}
              onChange={(e, value) => this.setState({ tabValue: value })}
              tabs={['見積明細', 'ファイル', '見積書出力履歴']}
            />
            <TabPanel value={tabValue} index={0}>
              <DetailTable
                rows={datas}
                headCells={this.detailCells}
                onAddOpen={this.onAdd}
                procName="見積明細"
                onDelete={this.onDelete}
                handleRequestSort={this.handleRequestSort}
                order={searchCondition.order}
                orderBy={searchCondition.orderBy}
                isNotDelete={isNotDelete}
                isNotEdit={isNotEdit}
                onEdit={this.onEdit}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <FileDataTable
                estimateId={estimate.id || null}
                opportunityId={estimate.opportunityId || null}
                procName="見積ファイル"
                defaultShop={{ value: estimate.shopId, name: estimate.shopName, detailType: 1 }}
                isNotDelete={isNotDelete}
                isNotEdit={isNotEdit}
                url={detailUrl.ESTIMATE_DETAIL}
                opportunityNum={estimate.opportunityNumber || null}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <HistoryData estimateId={estimate.id} />
            </TabPanel>
          </Grid>
          )}
        </Grid>
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message={successMessage}
        />
        <ConfirmDialog
          open={isCreateOrderConfirmOpen}
          onClose={this.onClose}
          onClick={this.onCreate}
          title="注文作成"
          content="現在の見積から注文を作成します。"
          actionText="注文作成"
        />
        <EstimateDetailDialog
          isOpen={isOpen}
          onClose={this.onClose}
          estimateId={estimate.id}
          detailId={openedMenuRowId}
          shopId={estimate.shopId}
        />
        <FullScreenMailer
          isOpen={isMailerOpen && isSendEstimate}
          loading={loading}
          onClose={() => this.setState({
            isMailerOpen: false,
            isSendEstimate: false,
          })}
          onClickAction={this.onSendFile}
          data={fileMailerData}
          title="見積書送付"
          objectData={estimate}
          leftArea={(
            <Box style={{ overflowY: 'auto' }}>
              <PDFViewer style={{ width: '100%', height: '85vh' }} showToolbar={false}>
                {estimate.englishReport
                  && <EstimateEnglishFile data={pdfData} />}
                {!estimate.englishReport
                  && estimate.affiliationId
                  && <EstimateAffiliationFile data={pdfData} />}
                {!estimate.englishReport
                  && !estimate.affiliationId
                  && <EstimateFile data={pdfData} />}
              </PDFViewer>
            </Box>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  estimateInfo: state.estimateStore,
  estimateDetailInfo: state.estimateDetailStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(EstimateDetail));
