import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField,
} from '@mui/material';
import { planActions } from '../../../redux/shop/plan/planState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { FormTitle, SuccessSnackbar } from '../../../components/atoms/Base';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';
import DataMultiSelectList from '../../../components/atoms/dataList/DataMultiSelectList';

const DEFAULT_SEARCH_CONDITION = {
  shopName: '',
  name: '',
  shopCode: '',
  serviceTypeCodes: [],
  keyword: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'shopId', label: '店舗ID' },
  { key: 'shopName', label: '店舗名' },
  { key: 'planCode', label: 'プランコード' },
  { key: 'serviceTypeCode', label: 'サービスタイプコード' },
  { key: 'serviceTypeName', label: 'サービスタイプ名' },
  { key: 'planTypeCode', label: 'プランタイプコード' },
  { key: 'planTypeName', label: 'プランタイプ名' },
  { key: 'name', label: 'プラン名' },
  { key: 'introduction', label: 'プラン紹介' },
  { key: 'itemNumber', label: '品目数' },
  { key: 'price', label: '税込価格' },
  { key: 'published', label: '公開' },
  { key: 'publishDt', label: '公開日時' },
  { key: 'detailContent', label: 'メニュー詳細' },
  { key: 'priceNote', label: '金額備考' },
  { key: 'accessory', label: '付属品' },
  { key: 'taxRate', label: '税率' },
  { key: 'displayOrder', label: '表示順' },
  { key: 'attributeItemName', label: '属性値' },
  { key: 'conciergeRecommended', label: 'コンシェルジュおすすめ' },
  { key: 'recommendPrefectureId', label: 'おすすめ都道府県ID' },
  { key: 'recommendPrefectureName', label: 'おすすめ都道府県名' },
  { key: 'recommendBudgetAttributeItemId', label: 'おすすめ予算属性項目ID' },
  { key: 'recommendBudgetAttributeItemName', label: 'おすすめ予算属性項目名' },
  { key: 'recommendGenreAttributeItemId', label: 'おすすめジャンル属性項目ID' },
  { key: 'recommendGenreAttributeItemName', label: 'おすすめジャンル属性項目名' },
  { key: 'recommendSceneAttributeItemId', label: 'おすすめシーン属性項目ID' },
  { key: 'recommendSceneAttributeItemName', label: 'おすすめシーン属性項目名' },
  { key: 'reviewId', label: 'ピックアップ口コミID' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

class PlanList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'name', label: 'プラン名', sortId: 'name' },
      { id: 'serviceTypeName', label: 'サービスタイプ', sortId: 'service_type_name' },
      { id: 'shopName', label: '店舗名', sortId: 'shop_name' },
      { id: 'itemNumber', label: '品目数', sortId: 'item_number' },
      {
        id: 'price', label: '価格', sortId: 'price', numeric: true,
      },
      { id: 'published', label: '公開', sortId: 'published' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;
    if (props.planInfo.searchCondition) {
      searchCondition = props.planInfo.searchCondition;
    }
    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      download: {
        headerItems: CSV_HEADER,
        fileName: 'プラン',
        done: false,
        datas: [],
      },
    };
    props.dispatch(planActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.planInfo.datas !== this.props.planInfo.datas) {
      if (this.props.planInfo.datas) {
        this.setDatas(this.props.planInfo.datas);
      }
    }
    if (prevProps.planInfo.csvDatas !== this.props.planInfo.csvDatas) {
      if (this.props.planInfo.csvDatas) {
        this.setCsvDatas(this.props.planInfo.csvDatas);
      }
    }
    if (prevProps.planInfo.isDeleteSuccess
      !== this.props.planInfo.isDeleteSuccess) {
      if (this.props.planInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
  }

  setSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  }

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  search = (searchCondition) => {
    this.props.dispatch(planActions.setSearchCondition(searchCondition));
    this.props.dispatch(planActions.getDatas(searchCondition));
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(planActions.deleteData({ ids }));
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(planActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      isDeleteDisabled,
      isEditDisabled,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          targetScreen="plan"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          onClear={this.onClear}
          simpleAreaOneText="店舗名"
          simpleAreaOne={(
            <TextField value={searchCondition.shopName} fullWidth name="shopName" onChange={this.onChange} />
          )}
          simpleAreaTwoText="プラン名"
          simpleAreaTwo={(
            <TextField value={searchCondition.name} fullWidth name="name" onChange={this.onChange} />
          )}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <FormTitle title="店舗コード" />
              <TextField value={searchCondition.shopCode} fullWidth name="shopCode" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTitle title="プランカテゴリ" />
              <DataMultiSelectList
                name="serviceTypeCodes"
                target="codes"
                parameter={{ codeCategory: 'service_type' }}
                values={searchCondition.serviceTypeCodes}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title="フリーワード" attention="プラン紹介、メニュー詳細" />
              <TextField value={searchCondition.keyword} autoComplete="off" fullWidth name="keyword" onChange={this.onChange} />
            </Grid>
          </Grid>
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.PLAN_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="プラン"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
          urlTargets={[{ url: detailUrl.SHOP_DETAIL, label: 'shopName', id: 'shopId' }]}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="削除しました"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  planInfo: state.planStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(PlanList);
