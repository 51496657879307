import { createSlice } from '@reduxjs/toolkit';

export const blogContentSlice = createSlice({
  name: 'blogContent',
  initialState: {
    datas: {},
    isSaveSuccess: false,
    isDeleteSuccess: false,
  },
  reducers: {
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    deleteDatas: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDatasSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    resetFlg: (state) => {
      state.isSaveSuccess = false;
      state.isDeleteSuccess = false;
    },
  },
});

export const blogContentActions = blogContentSlice.actions;

export default blogContentSlice.reducer;
