import React, { useState } from 'react';
import {
  Box, IconButton, TableCell, Menu, MenuItem,
  Table, TableBody, TableContainer, TableRow,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DataGrid from '../../../components/templates/DataGrid';
import { FormTitle, Link } from '../../../components/atoms/Base';
import RichTextReadOnly from '../../../components/templates/wysiwyg/RichTextReadOnly';
import PublicImageList from '../../../components/templates/publicImageList/PublicImageList';
import detailUrl from '../../../constants/frontUrls';

export default function BlogDetailInfoCard(props) {
  const { data, onEdit, onDelete } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setOpenDelete = (contentId) => {
    setAnchorEl(false);
    onDelete(contentId);
  };

  return (
    <div>
      <Paper style={{ marginBottom: '10px' }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableBody>
              <TableRow
                hover
                tabIndex={-1}
                key={`row-${data.id}`}
                sx={{ height: '56px' }}
              >
                <TableCell style={{
                  padding: '0px 10px',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                }}
                >
                  {data.contentType === 1 && (
                    <Box mt={1}>
                      <DataGrid title="リンク" value={<Link to={data.link} target="_blank">{data.link}</Link>} />
                      <DataGrid title="リンクテキスト" value={data.linkText} />
                    </Box>
                  )}
                  {data.contentType === 2 && (
                    <DataGrid title="画像" isEdit>
                      <PublicImageList
                        itemDatas={data ? [data] : []}
                      />
                    </DataGrid>
                  )}
                  {data.contentType === 3 && (
                    <Box mb={2} mt={1}>
                      <FormTitle title="テキスト" isNonFlex />
                      <RichTextReadOnly content={data.content} />
                    </Box>
                  )}
                  {data.contentType === 4 && (
                    <DataGrid
                      title="関連幹事のコツ"
                      value={(
                        <Link to={detailUrl.BLOG_DETAIL + data.relateBlogId}>
                          {data.relateBlogId}
                        </Link>
                      )}
                    />
                  )}
                  {data.contentType === 5 && (
                    <Box mb={2} mt={1}>
                      <FormTitle title="目次" isNonFlex />
                      <RichTextReadOnly content={data.content} />
                    </Box>
                  )}
                </TableCell>
                {(onEdit || onDelete) && (
                <TableCell style={{
                  padding: '0px',
                  position: 'sticky',
                  right: '0',
                  backgroundColor: 'white',
                  textAlign: 'right',
                }}
                >
                  <IconButton onClick={(event) => handleClick(event)}>
                    <ExpandMoreIcon size="small" />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {onEdit && (
                    <MenuItem
                      onClick={() => {
                        onEdit(data.id);
                        handleClose();
                      }}
                    >
                      編集
                    </MenuItem>
                    )}
                    {onDelete && (
                    <MenuItem
                      onClick={() => setOpenDelete(data.id)}
                    >
                      削除
                    </MenuItem>
                    )}
                  </Menu>
                </TableCell>
                )}

              </TableRow>
              {
                !data.id && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={1}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
