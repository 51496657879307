import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { blogContentActions } from './blogContentState';
import {
  getDatas, deleteDatas, getDataById, uploadFiles,
} from '../../../services/base.service';

function* getBlogContents({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.BLOG_CONTENT.BLOG_CONTENT, payload);
    yield put(blogContentActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.PAGE_SETTING.BLOG_CONTENT.BLOG_CONTENT_ID, payload);
    yield put(blogContentActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const formData = new FormData();

    const requestData = {};
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'imageFile') {
        requestData[key] = value;
      }
    });
    formData.append('request', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));
    formData.append('imageFile', payload.imageFile || null);
    const result = yield call(uploadFiles, REST_API.PAGE_SETTING.BLOG_CONTENT.BLOG_CONTENT, formData);
    yield put(blogContentActions.saveDataSuccess(result));
  } catch (_) { }
}

function* deleteBlogContents({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.PAGE_SETTING.BLOG_CONTENT.BLOG_CONTENT, payload);
    yield put(blogContentActions.deleteDatasSuccess(result));
  } catch (_) { }
}

function* blogContentSaga() {
  yield takeLatest('blogContent/getDatas', getBlogContents);
  yield takeLatest('blogContent/getData', getData);
  yield takeLatest('blogContent/saveData', save);
  yield takeLatest('blogContent/deleteDatas', deleteBlogContents);
}

export default blogContentSaga;
