import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, DialogActions,
} from '@mui/material';
import { claimDetailActions } from '../../../redux/opportunity/claim/claimDetailState';
import DataGrid from '../../../components/templates/DataGrid';
import Dialog from '../../../components/atoms/Dialog';
import NumberInput from '../../../components/atoms/NumberInput';
import Validation from './validation';
import CommonValidation from '../../../utils/common.validation';
import SelectStoreComponent from '../common/selectStoreComponent/SelectStoreComponent';
import {
  DialogContent, FormSwitch, FormErrorText, TaxRate,
} from '../../../components/atoms/Base';
import { emptyItemsOtherSelectedProduct, emptyItemsOtherSelectedStore } from '../../../utils/common.helper';
import ProductInputType from '../../../components/atoms/ProductInputType';
import SystemInfo from '../../../components/templates/SystemInfo';

const tmpRow = {
  id: null,
  detailType: 1,
  planId: null,
  vendorId: null,
  shopId: null,
  shopPlanOptionId: null,
  freeProductName: '',
  unitPrice: '',
  quantity: '',
  taxRate: 8,
  productInputTypeCode: 1,
  displayOrder: '',
  reportHidden: false,
  burdenPrice: '',
};

const tmpErrorMessage = {
  planId: '',
  vendorId: '',
  shopId: '',
  freeProductName: '',
  shopPlanOptionId: '',
  unitPrice: '',
  quantity: '',
  taxRate: '',
  displayOrder: '',
};

export default function ClaimDetailDialog(props) {
  const [row, setRow] = React.useState(tmpRow);
  const [detailType, setDetailType] = React.useState(1);
  const [errorMessage, setErrorMessage] = React.useState(tmpErrorMessage);
  const { isSaveSuccess } = useSelector((state) => state.claimDetailStore);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, claimDetailId, claimId,
  } = props;
  const data = useSelector((state) => state.claimDetailStore.data);

  React.useEffect(() => {
    if (isOpen) {
      setRow(tmpRow);
      setErrorMessage(tmpErrorMessage);
      setDetailType(1);
      if (claimDetailId) {
        setRow(data || tmpRow);
        dispatch(claimDetailActions.getData({ id: claimDetailId }));
      }
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      setRow(data);
      setDetailType(data.vendorId ? 2 : 1);
    }
  }, [data]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      onClose();
      dispatch(claimDetailActions.resetFlgs());
    }
  }, [isSaveSuccess]);

  const beforeSaveCheck = () => {
    let tempMessage = { ...tmpErrorMessage };
    let isError = false;
    Object.keys(row).forEach((key) => {
      if (key === 'freeProductName') return;
      const msg = Validation.detailFormValidate(key, row[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    const productCheckResult = CommonValidation.productValidate(row, tempMessage);
    if (productCheckResult.isError) {
      tempMessage = productCheckResult.tempMessage;
      isError = true;
    }
    const storeCheckResult = CommonValidation.storeValidate(row, tempMessage, detailType);
    if (storeCheckResult.isError) {
      tempMessage = storeCheckResult.tempMessage;
      isError = true;
    }
    setErrorMessage(tempMessage);
    return isError;
  };

  const onClickSave = () => {
    if (beforeSaveCheck()) return;
    let sendRow = { ...row, claimId };
    sendRow = emptyItemsOtherSelectedProduct(sendRow);
    sendRow = emptyItemsOtherSelectedStore(sendRow, detailType);
    dispatch(claimDetailActions.saveData(sendRow));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const newRow = {
      ...row,
      [name]: value,
    };
    setRow(newRow);
    setErrorMessage({ ...errorMessage, [name]: Validation.detailFormValidate(name, value) });
  };

  const detailTypeChange = (value) => {
    const newRow = {
      ...row,
      productInputTypeCode: value === 2 ? 2 : row.productInputTypeCode,
    };
    setRow(newRow);
    setDetailType(value);
  };

  const onStoreChange = (event) => {
    const { name, value } = event.target;
    const newRow = {
      ...row,
      [name]: value,
      shopPlanOptionId: '',
      planId: '',
    };
    setRow(newRow);
    setErrorMessage({ ...errorMessage, [name]: Validation.detailFormValidate(name, value) });
  };

  const onProductChange = (event) => {
    const {
      name, value, unitPrice, taxRate,
    } = event.target;

    const newRow = {
      ...row,
      unitPrice: unitPrice || row.unitPrice,
      taxRate: taxRate || row.taxRate,
      [name]: value,
    };

    setRow(newRow);
    setErrorMessage({
      ...errorMessage, unitPrice: unitPrice ? '' : errorMessage.unitPrice, taxRate: unitPrice ? '' : errorMessage.taxRate, [name]: Validation.detailFormValidate(name, value),
    });
  };

  const onChangeRadio = (event) => {
    const { name, value } = event.target;
    const newRow = {
      ...row,
      [name]: Number(value),
    };
    setRow(newRow);
    setErrorMessage({ ...errorMessage, [name]: Validation.detailFormValidate(name, value) });
  };

  return (
    <Dialog isOpen={isOpen} title="請求明細" fullWidth maxWidth="sm" onClose={onClose}>
      <DialogContent>
        <SelectStoreComponent
          detailType={detailType}
          row={row}
          onChange={onStoreChange}
          errorMessage={errorMessage}
          setDetailType={detailTypeChange}
          isEdit
        />
        <ProductInputType
          errorMessages={errorMessage}
          onChange={onProductChange}
          shopId={row.shopId}
          row={row}
          onChangeProductInputType={onChangeRadio}
          storeType={detailType}
        />
        <DataGrid title="税込単価" isRequired isEdit>
          <NumberInput name="unitPrice" fullWidth value={row.unitPrice} onChange={onChange} error={!!errorMessage.unitPrice} />
          <FormErrorText>{errorMessage.unitPrice}</FormErrorText>
        </DataGrid>
        <DataGrid title="個数" isRequired isEdit>
          <NumberInput name="quantity" value={row.quantity} onChange={onChange} error={!!errorMessage.quantity} isDecimal />
          <FormErrorText>{errorMessage.quantity}</FormErrorText>
        </DataGrid>
        <DataGrid title="税率" isRequired isEdit>
          <TaxRate value={row.taxRate} onChange={onChange} name="taxRate" error={!!errorMessage.taxRate} />
          <FormErrorText>{errorMessage.taxRate}</FormErrorText>
        </DataGrid>
        <DataGrid title="Cqree負担額(税込)" isEdit>
          <NumberInput value={row.burdenPrice} name="burdenPrice" fullWidth onChange={onChange} />
        </DataGrid>
        <DataGrid title="表示順" isRequired isEdit>
          <NumberInput name="displayOrder" value={row.displayOrder} onChange={onChange} error={!!errorMessage.displayOrder} />
          <FormErrorText>{errorMessage.displayOrder}</FormErrorText>
        </DataGrid>
        <DataGrid title="請求対象" isEdit>
          <FormSwitch checked={row.claimed} onChange={onChange} label="請求対象にする" name="claimed" />
        </DataGrid>
        <DataGrid title="帳票非表示" isEdit>
          <FormSwitch checked={row.reportHidden} onChange={onChange} label="帳票非表示にする" name="reportHidden" />
        </DataGrid>
        {row.id && (
          <Box mt={2} fontSize={14}><SystemInfo data={row} /></Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
