import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, DialogActions, TextField,
} from '@mui/material';
import Validation from './validation';
import { blogContentActions } from '../../../redux/pageSetting/blog/blogContentState';
import DataGrid from '../../../components/templates/DataGrid';
import Dialog from '../../../components/atoms/Dialog';
import {
  FormErrorText, Link, FormTitle, DialogContent,
} from '../../../components/atoms/Base';
import ContentTypeRadio from './ContentTypeRadio';
import detailUrl from '../../../constants/frontUrls';
import SelectBlogDialog from './selectBlogDialog/SelectBlogDialog';
import Editor from '../../../components/templates/wysiwyg/Editor';
import SingleFileInput from '../../../components/templates/SingleFileInput';
import NumberInput from '../../../components/atoms/NumberInput';

const DEFAULT_FORM = {
  id: '',
  blogId: '',
  contentType: 1,
  link: '',
  linkText: '',
  imagePath: '',
  content: '',
  relateBlogId: '',
  displayOrder: '',
};

const DEFAULT_ERROR_MESSAGE = {
  blogId: '',
  contentType: '',
  displayOrder: '',
};

export default function BlogContentDialog(props) {
  const [form, setForm] = React.useState(DEFAULT_FORM);
  const [isSelectOpen, setSelectOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, blogId, blogContentId,
  } = props;
  const data = useSelector((state) => state.blogContentStore.data);
  const [errorMessages, setErrorMessages] = useState(DEFAULT_ERROR_MESSAGE);

  React.useEffect(() => {
    if (isOpen) {
      if (blogContentId) {
        dispatch(blogContentActions.getData({ id: blogContentId }));
      }
      setForm(DEFAULT_FORM);
      setErrorMessages(DEFAULT_ERROR_MESSAGE);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (blogContentId && data) {
      setForm(data);
    }
  }, [data]);

  const beforeSaveCheck = (newRow) => {
    let tempMessages = errorMessages;
    let isError = false;

    Object.keys(newRow).forEach((key) => {
      const msg = Validation.formValidate(key, newRow[key]);
      if (msg) {
        isError = true;
      }
      tempMessages = {
        ...tempMessages,
        [key]: msg,
      };
    });
    return isError;
  };

  /**
 * コンテンツタイプに対応しない項目を空に
 * @param {*}
 * @param {*}
 */
  const emptyItemsOtherSelectedContent = () => {
    const tempForm = { ...form };
    switch (form.contentType) {
      // リンク
      case 1:
        tempForm.imagePath = null;
        tempForm.content = null;
        tempForm.relateBlogId = null;
        break;
      // 画像
      case 2:
        tempForm.link = null;
        tempForm.linkText = null;
        tempForm.content = null;
        tempForm.relateBlogId = null;
        break;
      // テキスト, 目次
      case 3:
      case 5:
        tempForm.link = null;
        tempForm.linkText = null;
        tempForm.imagePath = null;
        tempForm.relateBlogId = null;
        break;
      // 関連幹事のコツ
      case 4:
        tempForm.link = null;
        tempForm.linkText = null;
        tempForm.imagePath = null;
        tempForm.content = null;
        break;
      default:
        break;
    }
    return tempForm;
  };

  const onClickSave = () => {
    if (beforeSaveCheck(form)) return;
    const newRow = emptyItemsOtherSelectedContent();
    const saveForm = {
      ...newRow,
      blogId,
    };
    dispatch(blogContentActions.saveData(saveForm));
    onClose();
  };

  const onChange = (event) => {
    const {
      name,
      value,
    } = event.target;
    let newRow = form;
    let newMessage = errorMessages;
    newRow = {
      ...newRow,
      [name]: value,
    };
    newMessage = {
      ...newMessage,
      [name]: Validation.formValidate(name, value),
    };
    setForm(newRow);
    setErrorMessages(newMessage);
  };

  const onSelect = (_, selected) => {
    setForm({ ...form, relateBlogId: selected.id });
    setErrorMessages({ ...errorMessages, relateBlogId: '' });
    setSelectOpen(false);
  };

  return (
    <>
      <Dialog isOpen={isOpen} title="幹事のコツコンテンツ" fullWidth maxWidth="sm" onClose={onClose}>
        <DialogContent>
          <Box>
            <DataGrid title="コンテンツタイプ" value={form.contentType} isEdit isRequired>
              <ContentTypeRadio
                error={errorMessages.contentType}
                value={form.contentType}
                onChange={onChange}
              />
              <FormErrorText>{errorMessages.contentType}</FormErrorText>
            </DataGrid>
            {form.contentType === 1 && (
            <>
              <DataGrid title="リンク" isEdit value={form.link}>
                <TextField autoComplete="off" value={form.link} fullWidth name="link" onChange={onChange} />
              </DataGrid>
              <DataGrid title="リンクテキスト" isEdit value={form.linkText}>
                <TextField autoComplete="off" value={form.linkText} fullWidth name="linkText" onChange={onChange} />
              </DataGrid>
            </>
            )}
            {form.contentType === 2 && (
            <SingleFileInput
              title="イメージ"
              isEdit
              imagePath="imagePath"
              imageFile="imageFile"
              isImageOnly
              form={form}
              inputForm={form}
              setInputForm={setForm}
            />
            )}
            {(form.contentType === 3 || form.contentType === 5) && (
            <Box mb={2} mt={1}>
              <FormTitle title="テキスト" isNonFlex />
              <Editor title="テキスト" name="content" onChange={onChange} content={form.content} />
            </Box>
            )}
            {form.contentType === 4 && (
            <DataGrid
              title="関連幹事のコツ"
              value={(
                <Link to={detailUrl.BLOG_DETAIL + form.relateBlogId}>
                  {form.relateBlogId}
                </Link>
              )}
              isEdit
            >
              <Box style={{ display: 'flex' }} mt={2}>
                {form.relateBlogId}
                <Button style={{ marginLeft: 'auto' }} size="small" variant="contained" onClick={() => setSelectOpen(true)}>関連幹事のコツ選択</Button>
              </Box>
            </DataGrid>
            )}
            <DataGrid taxRate title="表示順" isRequired isEdit>
              <NumberInput error={!!errorMessages.displayOrder} name="displayOrder" fullWidth value={form.displayOrder} onChange={onChange} />
              <FormErrorText>{errorMessages.displayOrder}</FormErrorText>
            </DataGrid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">キャンセル</Button>
          <Button onClick={onClickSave}>保存</Button>
        </DialogActions>
      </Dialog>
      <SelectBlogDialog
        isOpen={isSelectOpen}
        onSelect={onSelect}
        onClose={() => setSelectOpen(false)}
      />
    </>

  );
}
