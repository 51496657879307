import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Box,
} from '@mui/material';
import { contactListActions } from '../../../redux/opportunity/contact/contactListState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import {
  FormTitle, DateTimePicker, DatePicker, SuccessSnackbar, FormSwitch,
} from '../../../components/atoms/Base';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import DataMultiSelectList from '../../../components/atoms/dataList/DataMultiSelectList';
import { isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

export const DEFAULT_SEARCH_CONDITION = {
  opportunityNumber: '',
  contactCategoryCodes: [],
  statuses: [],
  salesUsers: [],
  contactDate: '',
  companyName: '',
  customerName: '',
  customerEmail: '',
  customerPhone: '',
  complianceDtStart: '',
  complianceDtEnd: '',
  keyword: '',
  unassigned: false,
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'contactCategoryCode', label: '問合せ種別コード' },
  { key: 'contactCategoryName', label: '問合せ種別名' },
  { key: 'contactStatusCode', label: 'ステータスコード' },
  { key: 'contactStatusName', label: 'ステータス名' },
  { key: 'shopId', label: '店舗ID' },
  { key: 'shopName', label: '店舗名' },
  { key: 'planId', label: 'プランID' },
  { key: 'planName', label: 'プラン名' },
  { key: 'companyName', label: '会社名' },
  { key: 'customerName', label: 'お客様名' },
  { key: 'customerPhone', label: '電話番号' },
  { key: 'customerEmail', label: 'メール' },
  { key: 'content', label: '問合せ内容' },
  { key: 'remarks', label: 'メモ' },
  { key: 'salesUserId', label: '担当者ID' },
  { key: 'salesUserName', label: '担当者名' },
  { key: 'complianceDt', label: '対応期日日時' },
  { key: 'opportunityId', label: '案件ID' },
  { key: 'opportunityNumber', label: '案件番号' },

  { key: 'orderType', label: '注文種別' },
  { key: 'scheduleDateText', label: '開催予定日' },
  { key: 'useSceneType', label: '利用シーン' },
  { key: 'readyStartTime', label: '準備開始可能時間(搬入時間)' },
  { key: 'eventEndTime', label: '終演時間' },
  { key: 'eventWithdrawalTime', label: '撤収完了時間' },
  { key: 'deliveryTime', label: 'お届け時間' },
  { key: 'venueAddress', label: '住所' },
  { key: 'budget', label: 'ご予算' },
  { key: 'attendanceNumber', label: '参加人数' },
  { key: 'mainAge', label: '主な参加者の年代' },
  { key: 'eventType', label: 'イベント形式' },
  { key: 'foodHope', label: 'お料理のご希望' },
  { key: 'tableClothTarget', label: 'テーブルクロスを使用する対象' },
  { key: 'drinkType', label: 'ドリンク種類' },
  { key: 'offerType', label: 'ドリンク提供方法' },
  { key: 'cutleryType', label: 'カトラリー' },
  { key: 'plateType', label: '取り皿' },
  { key: 'drinkGlassType', label: '飲み物グラス' },
  { key: 'staffStayType', label: '開演中のスタッフ常駐' },
  { key: 'decorationHope', label: '装飾のご希望' },
  { key: 'venueReserve', label: '会場の準備' },
  { key: 'venueExtent', label: 'おおよその会場の広さ' },
  { key: 'useTable', label: '利用できるテーブル' },
  { key: 'useFireType', label: '火器使用' },
  { key: 'useElectricityType', label: '電気設備' },
  { key: 'backyardType', label: 'バックヤード' },
  { key: 'pantryType', label: 'パントリー' },
  { key: 'venueSetupStaffType', label: 'スタッフによる会場設営' },
  { key: 'trolleyType', label: '台車の利用' },
  { key: 'parkingType', label: '搬入車駐車場' },
  { key: 'carryRequestType', label: '搬入事前申請' },
  { key: 'carryLimit', label: '搬入についての制限' },
  { key: 'paymentMethodType', label: 'お支払い' },

  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

class ContactList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'contactNumber', label: '問合せ番号', sortId: 'contact_number' },
      { id: 'contactDate', label: '問合せ日時', sortId: 'contact_date' },
      { id: 'companyName', label: '会社名', sortId: 'company_name' },
      { id: 'customerName', label: 'お客様名', sortId: 'customer_name' },
      { id: 'customerEmail', label: 'メールアドレス', sortId: 'customer_email' },
      { id: 'customerPhone', label: '電話番号', sortId: 'customer_phone' },
      { id: 'contactCategoryName', label: '問合せ種別', sortId: 'contact_category_name' },
      { id: 'salesUserName', label: '担当者', sortId: 'sales_user_name' },
      { id: 'contactStatusName', label: 'ステータス', sortId: 'contact_status_name' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.contactInfo.searchCondition) {
      searchCondition = props.contactInfo.searchCondition;
    }

    this.state = {
      isDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      download: {
        headerItems: CSV_HEADER,
        fileName: '問合せ',
        done: false,
        datas: [],
      },
    };
    props.dispatch(contactListActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contactInfo.datas !== this.props.contactInfo.datas) {
      if (this.props.contactInfo.datas) {
        this.setDatas(this.props.contactInfo.datas);
      }
    }
    if (prevProps.contactInfo.csvDatas !== this.props.contactInfo.csvDatas) {
      if (this.props.contactInfo.csvDatas) {
        this.setCsvDatas(this.props.contactInfo.csvDatas);
      }
    }
    if (prevProps.contactInfo.isDeleteSuccess
      !== this.props.contactInfo.isDeleteSuccess) {
      if (this.props.contactInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
  }

  setSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  }

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  search = (searchCondition) => {
    this.props.dispatch(contactListActions.setSearchCondition(searchCondition));
    this.props.dispatch(contactListActions.getDatas(searchCondition));
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
    this.props.dispatch(contactListActions.setSearchCondition(DEFAULT_SEARCH_CONDITION));
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(contactListActions.deleteData({ ids }));
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(contactListActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      isDisabled,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          targetScreen="contact"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          onClear={this.onClear}
          simpleAreaOneText="ステータス"
          simpleAreaOne={(
            <DataMultiSelectList
              name="statuses"
              target="codes"
              parameter={{ codeCategory: 'contact_status' }}
              values={searchCondition.statuses}
              onChange={this.onChange}
              fullWidth
            />
          )}
          simpleAreaTwoText="担当者"
          simpleAreaTwo={(
            <DataMultiSelectList
              name="salesUsers"
              target="users"
              values={searchCondition.salesUsers}
              onChange={this.onChange}
              fullWidth
            />
          )}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="案件番号" />
              <TextField value={searchCondition.opportunityNumber} fullWidth name="opportunityNumber" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="問合せ種別" />
              <DataMultiSelectList
                name="contactCategoryCodes"
                target="codes"
                parameter={{ codeCategory: 'contact_category' }}
                values={searchCondition.contactCategoryCodes}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="問合せ日" />
              <DatePicker onChange={this.onChange} name="contactDate" value={searchCondition.contactDate} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="対応期限日時" />
              <Box display="flex" alignItems="center">
                <Box>
                  <DateTimePicker
                    onChange={this.onChange}
                    value={searchCondition.complianceDtStart}
                    name="complianceDtStart"
                  />
                </Box>
                <Box px={2}>～</Box>
                <Box>
                  <DateTimePicker
                    onChange={this.onChange}
                    value={searchCondition.complianceDtEnd}
                    name="complianceDtEnd"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="お客様名" />
              <TextField value={searchCondition.customerName} fullWidth name="customerName" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="会社名" />
              <TextField value={searchCondition.companyName} fullWidth name="companyName" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="メールアドレス" />
              <TextField value={searchCondition.customerEmail} fullWidth name="customerEmail" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="電話番号" />
              <TextField value={searchCondition.customerPhone} fullWidth name="customerPhone" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="担当者未割当のみ" />
              <FormSwitch checked={searchCondition.unassigned} name="unassigned" onChange={this.onChange} label="未割当のみ" />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title="フリーワード" attention="問合せ内容、メモ" />
              <TextField value={searchCondition.keyword} autoComplete="off" fullWidth name="keyword" onChange={this.onChange} />
            </Grid>
          </Grid>
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.CONTACT_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="問合せ"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotDelete={isDisabled}
          isDispFire
          isNotAdd
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="削除しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  contactInfo: state.contactListStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(ContactList);
