import React from 'react';

import ContactDetail from '../pages/opportunity/contact/ContactDetail';
import ContactList from '../pages/opportunity/contact/ContactList';
import OpportunityList from '../pages/opportunity/opportunity/list/OpportunityList';
import OpportunityDetail from '../pages/opportunity/opportunity/detail/OpportunityDetail';
import EstimateList from '../pages/opportunity/estimate/EstimateList';
import EstimateDetail from '../pages/opportunity/estimate/detail/EstimateDetail';
import OrderList from '../pages/opportunity/order/OrderList';
import OrderDetail from '../pages/opportunity/order/OrderDetail';
import ClaimList from '../pages/opportunity/claim/ClaimList';
import ClaimDetail from '../pages/opportunity/claim/ClaimDetail';
import CustomerList from '../pages/opportunity/customer/CustomerList';
import CustomerDetail from '../pages/opportunity/customer/CustomerDetail';

import AccountList from '../pages/shop/account/AccountList';
import AccountDetail from '../pages/shop/account/AccountDetail';
import ShopList from '../pages/shop/shop/ShopList';
import ShopDetail from '../pages/shop/shop/ShopDetail';
import ShopNoticeList from '../pages/shop/shopNotice/ShopNoticeList';
import ShopNoticeDetail from '../pages/shop/shopNotice/ShopNoticeDetail';
import PlanList from '../pages/shop/plan/PlanList';
import PlanDetail from '../pages/shop/plan/PlanDetail';
import VendorList from '../pages/shop/vendor/VendorList';
import VendorDetail from '../pages/shop/vendor/VendorDetail';
import DeliveryAgencyList from '../pages/shop/deliveryAgency/DeliveryAgencyList';
import DeliveryAgencyDetail from '../pages/shop/deliveryAgency/DeliveryAgencyDetail';

import ReviewList from '../pages/pageSetting/review/ReviewList';
import ReviewDetail from '../pages/pageSetting/review/ReviewDetail';
import IllustrationList from '../pages/pageSetting/illustration/IllustrationList';
import IllustrationDetail from '../pages/pageSetting/illustration/IllustrationDetail';
import SpecialFeatureList from '../pages/pageSetting/specialFeature/SpecialFeatureList';
import SpecialFeatureDetail from '../pages/pageSetting/specialFeature/SpecialFeatureDetail';
import BlogList from '../pages/pageSetting/blog/BlogList';
import BlogDetail from '../pages/pageSetting/blog/BlogDetail';

import RoleList from '../pages/systemSetting/role/RoleList';
import RoleDetail from '../pages/systemSetting/role/RoleDetail';
import LoginUserList from '../pages/systemSetting/loginUser/LoginUserList';
import LoginUserDetail from '../pages/systemSetting/loginUser/LoginUserDetail';
import PointRuleList from '../pages/systemSetting/pointRule/PointRuleList';
import PointRuleDetail from '../pages/systemSetting/pointRule/PointRuleDetail';
import MailTemplateList from '../pages/systemSetting/mailTemplate/MailTemplateList';
import MailTemplateDetail from '../pages/systemSetting/mailTemplate/MailTemplateDetail';
import TrashList from '../pages/systemSetting/trash/TrashList';

const detailUrl = {
  CONTACT_DETAIL: '/contact/detail/',
  OPPORTUNITY_DETAIL: '/opportunity/detail/',
  ESTIMATE_DETAIL: '/estimate/detail/',
  ORDER_DETAIL: '/order/detail/',
  CLAIM_DETAIL: '/claim/detail/',
  CUSTOMER_DETAIL: '/customer/detail/',

  ACCOUNT_DETAIL: '/account/detail/',
  SHOP_DETAIL: '/shop/detail/',
  SHOP_NOTICE_DETAIL: '/shop-notice/detail/',
  DELIVERY_AGENCY_DETAIL: '/delivery-agency/detail/',
  PLAN_DETAIL: '/plan/detail/',

  REVIEW_DETAIL: '/review/detail/',
  ILLUSTRATION_DETAIL: '/illustration/detail/',
  ROLE_DETAIL: '/role/detail/',
  LOGIN_USER_DETAIL: '/login-user/detail/',
  SPECIAL_FEATURE_DETAIL: '/special-feature/detail/',
  BLOG_DETAIL: '/blog/detail/',
  POINT_RULE_DETAIL: '/point-rule/detail/',
  MAIL_TEMPLATE_DETAIL: '/mail-template/detail/',
  VENDOR_DETAIL: '/vendor/detail/',
};
export default detailUrl;

export const urls = {
  COMMON: {
    HOME: { name: 'home', url: '/' },
    LOGIN: { name: 'ログイン', url: '/login' },
    SYSTEM_ERROR: { name: 'エラー', url: '/error' },
  },
  OPPORTUNITY: [
    {
      name: '問合せ', url: '/contact', component: <ContactList />, isMenuDisplay: true,
    },
    { name: '問合せ詳細', url: detailUrl.CONTACT_DETAIL, component: <ContactDetail /> },
    { name: '問合せ詳細', url: `${detailUrl.CONTACT_DETAIL}:id`, component: <ContactDetail /> },
    {
      name: '案件', url: '/opportunity', component: <OpportunityList />, isMenuDisplay: true,
    },
    { name: '案件詳細', url: detailUrl.OPPORTUNITY_DETAIL, component: <OpportunityDetail /> },
    { name: '案件詳細', url: `${detailUrl.OPPORTUNITY_DETAIL}:id`, component: <OpportunityDetail /> },
    {
      name: '見積', url: '/estimate', component: <EstimateList />, isMenuDisplay: true,
    },
    { name: '見積詳細', url: detailUrl.ESTIMATE_DETAIL, component: <EstimateDetail /> },
    { name: '見積詳細', url: `${detailUrl.ESTIMATE_DETAIL}:id`, component: <EstimateDetail /> },
    {
      name: '注文', url: '/order', component: <OrderList />, isMenuDisplay: true,
    },
    { name: '注文詳細', url: detailUrl.ORDER_DETAIL, component: <OrderDetail /> },
    { name: '注文詳細', url: `${detailUrl.ORDER_DETAIL}:id`, component: <OrderDetail /> },
    {
      name: '請求', url: '/claim', component: <ClaimList />, isMenuDisplay: true,
    },
    { name: '請求詳細', url: detailUrl.CLAIM_DETAIL, component: <ClaimDetail /> },
    { name: '請求詳細', url: `${detailUrl.CLAIM_DETAIL}:id`, component: <ClaimDetail /> },
    {
      name: '会員', url: '/customer', component: <CustomerList />, isMenuDisplay: true,
    },
    { name: '会員詳細', url: detailUrl.CUSTOMER_DETAIL, component: <CustomerDetail /> },
    { name: '会員詳細', url: `${detailUrl.CUSTOMER_DETAIL}:id`, component: <CustomerDetail /> },
  ],
  SHOP: [
    {
      name: '加盟企業一覧', url: '/account', component: <AccountList />, isMenuDisplay: true,
    },
    { name: '加盟企業詳細', url: `${detailUrl.ACCOUNT_DETAIL}:id`, component: <AccountDetail /> },
    {
      name: '店舗', url: '/shop', component: <ShopList />, isMenuDisplay: true,
    },
    { name: '店舗詳細', url: detailUrl.SHOP_DETAIL, component: <ShopDetail /> },
    { name: '店舗詳細', url: `${detailUrl.SHOP_DETAIL}:id`, component: <ShopDetail /> },
    {
      name: '店舗お知らせ', url: '/shop-notice', component: <ShopNoticeList />, isMenuDisplay: true,
    },
    { name: '店舗お知らせ詳細', url: detailUrl.SHOP_NOTICE_DETAIL, component: <ShopNoticeDetail /> },
    { name: '店舗お知らせ詳細', url: `${detailUrl.SHOP_NOTICE_DETAIL}:id`, component: <ShopNoticeDetail /> },
    {
      name: 'プラン', url: '/plan', isMenuDisplay: true, component: <PlanList />,
    },
    { name: 'プラン詳細', url: detailUrl.PLAN_DETAIL, component: <PlanDetail /> },
    { name: 'プラン詳細', url: `${detailUrl.PLAN_DETAIL}:id`, component: <PlanDetail /> },
    {
      name: '業者', url: '/vendor', component: <VendorList />, isMenuDisplay: true,
    },
    { name: '業者詳細', url: detailUrl.VENDOR_DETAIL, component: <VendorDetail /> },
    { name: '業者詳細', url: `${detailUrl.VENDOR_DETAIL}:id`, component: <VendorDetail /> },
    {
      name: '配送会社', url: '/delivery-agency', component: <DeliveryAgencyList />, isMenuDisplay: true,
    },
    { name: '配送会社詳細', url: detailUrl.DELIVERY_AGENCY_DETAIL, component: <DeliveryAgencyDetail /> },
    { name: '配送会社詳細', url: `${detailUrl.DELIVERY_AGENCY_DETAIL}:id`, component: <DeliveryAgencyDetail /> },
  ],
  PAGE_SETTING: [
    {
      name: '口コミ', url: '/review', isMenuDisplay: true, component: <ReviewList />,
    },
    { name: '口コミ詳細', url: detailUrl.REVIEW_DETAIL, component: <ReviewDetail /> },
    { name: '口コミ詳細', url: `${detailUrl.REVIEW_DETAIL}:id`, component: <ReviewDetail /> },
    {
      name: '特集', url: '/special-feature', isMenuDisplay: true, component: <SpecialFeatureList />,
    },
    { name: '特集詳細', url: detailUrl.SPECIAL_FEATURE_DETAIL, component: <SpecialFeatureDetail /> },
    { name: '特集詳細', url: `${detailUrl.SPECIAL_FEATURE_DETAIL}:id`, component: <SpecialFeatureDetail /> },
    {
      name: '実例', url: '/illustration', component: <IllustrationList />, isMenuDisplay: true,
    },
    { name: '実例詳細', url: detailUrl.ILLUSTRATION_DETAIL, component: <IllustrationDetail /> },
    { name: '実例詳細', url: `${detailUrl.ILLUSTRATION_DETAIL}:id`, component: <IllustrationDetail /> },
    {
      name: '幹事のコツ', url: '/blog', isMenuDisplay: true, component: <BlogList />,
    },
    { name: '幹事のコツ詳細', url: detailUrl.BLOG_DETAIL, component: <BlogDetail /> },
    { name: '幹事のコツ詳細', url: `${detailUrl.BLOG_DETAIL}:id`, component: <BlogDetail /> },
  ],
  SYSTEM_SETTING: [
    {
      name: '権限', url: '/role', isMenuDisplay: true, component: <RoleList />,
    },
    { name: '権限詳細', url: `${detailUrl.ROLE_DETAIL}:id`, component: <RoleDetail /> },
    {
      name: 'ログインユーザー', url: '/login-user', isMenuDisplay: true, component: <LoginUserList />,
    },
    { name: 'ログインユーザー詳細', url: detailUrl.LOGIN_USER_DETAIL, component: <LoginUserDetail /> },
    { name: 'ログインユーザー詳細', url: `${detailUrl.LOGIN_USER_DETAIL}:id`, component: <LoginUserDetail /> },
    {
      name: 'ポイントルール', url: '/point-rule', isMenuDisplay: true, component: <PointRuleList />,
    },
    { name: 'ポイントルール詳細', url: detailUrl.POINT_RULE_DETAIL, component: <PointRuleDetail /> },
    { name: 'ポイントルール詳細', url: `${detailUrl.POINT_RULE_DETAIL}:id`, component: <PointRuleDetail /> },
    {
      name: 'メールテンプレート', url: '/mail-template', isMenuDisplay: true, component: <MailTemplateList />,
    },
    { name: 'メールテンプレート詳細', url: detailUrl.MAIL_TEMPLATE_DETAIL, component: <MailTemplateDetail /> },
    { name: 'メールテンプレート詳細', url: `${detailUrl.MAIL_TEMPLATE_DETAIL}:id`, component: <MailTemplateDetail /> },
    {
      name: 'ごみ箱', url: '/trash', isMenuDisplay: true, component: <TrashList />,
    },
  ],
  PROFILE: {
    PASSWORD: { name: 'パスワード変更', url: '/change-password' },
  },
};
