import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, TextField, FormHelperText,
} from '@mui/material';
import DataGrid from '../../../../components/templates/DataGrid';
import Accordion from '../../../../components/templates/Accordion';
import detailUrl from '../../../../constants/frontUrls';
import { opportunityDetailActions } from '../../../../redux/opportunity/opportunity/opportunityDetailState';
import Validation from './validation';
import NumberInput from '../../../../components/atoms/NumberInput';
import {
  RecordBox, FormErrorText, Link, TextArea, DatePicker, DateTimePicker,
  FormSwitch, TimePicker,
} from '../../../../components/atoms/Base';
import SystemInfo from '../../../../components/templates/SystemInfo';
import DataSelectList from '../../../../components/atoms/dataList/DataSelectList';
import PrefectureInput from '../../../../components/atoms/PrefectureInput';
import AffiliationConfirmDialog from './AffiliationConfirmDialog';
import CustomerConfirmDialog from './CustomerConfirmDialog';
import { RecordHeaderButton } from '../../../../components/templates/RecordHeader';

const DEFALUT_ERROR_MESSAGE = {};

export default function OpportunityDetailInfo(props) {
  const { id, isDisabled } = props;

  const BASE_FORM = {
    id,
    customerName: '', // お客様名
    opportunityNumber: '', // 案件番号
    salesUserId: '', // 営業(id)
    salesUserName: '', // 営業名
    receptionDate: '', // 注文受付日
    fireComplianceDt: '', // 対応期限日時
    complianceDt: '',
    opportunityStatusCode: '', // ステータス
    opportunityStatusName: '', // ステータス名
    totalAmount: '', // 総合計金額
    heldDate: '', // 開催日
    heldCharTime: '', // 開催時間
    orderRouteCode: '', // 受注経路(id)
    orderRouteName: '', // 受注経路名
    paymentMethodId: '', // 支払方法
    paymentMethodName: '', // 支払方法名
    receiptRequired: false, // 領収書
    prefectureName: '', // // 納品先都道府県名
    prefectureId: '',
    cityName: '',
    otherAddress: '',
    deliveryAddress: '', // 納品先住所
    deliveryAddressStreet: '', // 納品先住所（番地以降の住所）
    deliveryRouteText: '', // 搬入経路
    deliveryRouteFile: [],
    receiptAddress: '', // 領収書宛名
    proviso: '', // 但書注意
    shopSend: false, // 確定メール送付
    customerSend: false, // 確定メール送付(客)
    isClaimed: false, // 請求済
    yuizenArranged: false, // 結膳手配有無
    affiliationId: '', // 提携企業(id)
    affiliationName: '', // 提携企業
    suggestAffiliation: 0, // 提携企業候補数
    companyName: '', // 所属先会社
    branchName: '', // 支店営業所
    customerId: '',
    customerIdName: '', // 会員名
    customerPhone: '', // 電話
    customerEmail: '', // メール
    poNumber: '', // PO番号
    agencyNumber: '', // 代行案件数
    yuizenShopNumber: '', // 結膳店舗数
    orderRemarks: '', // 注文状態詳細
    insideRemarks: '', // 社内用備考
    shopRemarks: '', // 店舗向け備考
    productRemarks: '', // 商品内容備考
    customerRemarks: '', // ユーザー向け備考
    customerRequest: '', // 顧客リクエスト
    pointGranted: false, // ポイント付与済
    reviewTargeted: true, // 口コミ受付対象フラグ
    illustrationTargeted: true, // 実例作成対象フラグ
    joinNumber: '',
    customerChecked: false,
  };

  const dispatch = useDispatch();
  const [form, setForm] = useState(BASE_FORM);
  const [inputForm, setInputForm] = useState(BASE_FORM);

  const [isEdit, setIsEdit] = useState(!id);
  const [errorMessages, setErrorMessages] = useState(DEFALUT_ERROR_MESSAGE);
  const [openAffiliation, setOpenAffiliation] = useState(false);
  const [openCustomer, setOpenCustomer] = useState(false);
  const data = useSelector((state) => state.opportunityDetailStore.data);
  const isSaveSuccess = useSelector((state) => state.opportunityDetailStore.isSaveSuccess);

  React.useEffect(() => {
    if (id && data) {
      setForm(data);
      setInputForm(data);
    }
  }, [data]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      setIsEdit(false);
    }
  }, [isSaveSuccess]);

  const onEdit = () => {
    setIsEdit(true);
    setInputForm(BASE_FORM);
    setErrorMessages(DEFALUT_ERROR_MESSAGE);
    dispatch(opportunityDetailActions.getData({ id: form.id }));
  };

  const beforeSaveCheck = (checkform, errorMessage) => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(checkform).forEach((key) => {
      const msg = Validation.formValidate(key, checkform[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (!checkform.customerName && !checkform.customerId) {
      const nameError = '会員かお客様名の入力は必須です';
      tempMessage = {
        ...tempMessage,
        customerName: nameError,
        customerId: nameError,
      };
      isError = true;
    }

    setErrorMessages(tempMessage);
    return isError;
  };

  const onSave = () => {
    if (beforeSaveCheck(inputForm, errorMessages)) {
      return;
    }
    if (inputForm.opportunityStatusCode !== form.opportunityStatusCode
      && inputForm.opportunityStatusCode === 3) {
      dispatch(opportunityDetailActions.setChangeStatus());
    }
    dispatch(opportunityDetailActions.saveData(inputForm));
  };

  const onEditCancel = () => {
    setIsEdit(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const changeTempForm = {
      ...inputForm,
      [name]: value,
    };
    setInputForm(changeTempForm);
    setErrorMessages({ ...errorMessages, [name]: Validation.formValidate(name, value) });
  };

  const onChangeCustomer = (event) => {
    const { name, value } = event.target;
    const changeTempForm = {
      ...inputForm,
      [name]: value,
    };
    const nameError = !changeTempForm.customerName && !changeTempForm.customerId ? '会員かお客様名の入力は必須です' : '';
    setInputForm(changeTempForm);
    setErrorMessages({
      ...errorMessages,
      customerName: nameError,
      customerId: nameError,
    });
  };

  return (
    <>
      <RecordHeaderButton
        edit={isEdit}
        disabled={isDisabled}
        onEdit={onEdit}
      />
      <RecordBox>

        <Accordion title="基本情報">
          {!isEdit && form.suggestAffiliation > 0 && (
            <DataGrid title="提携企業サジェスト" value={<Button onClick={() => setOpenAffiliation(true)}>{`候補が${form.suggestAffiliation}件あります`}</Button>} />
          )}
          {!isEdit && form.suggestCustomer > 0 && (
            <DataGrid title="会員サジェスト" value={<Button onClick={() => setOpenCustomer(true)}>{`候補が${form.suggestCustomer}件あります`}</Button>} />
          )}
          <DataGrid title="お客様名" value={form.customerName} isEdit={isEdit}>
            <TextField autoComplete="off" inputProps={{ maxLength: 255 }} error={!!errorMessages.customerName} value={inputForm.customerName} fullWidth name="customerName" onChange={onChangeCustomer} />
            <FormErrorText>{errorMessages.customerName}</FormErrorText>
          </DataGrid>
          <DataGrid title="会員" value={form.customerId ? <Link to={detailUrl.CUSTOMER_DETAIL + form.customerId}>{form.customerIdName}</Link> : ''} isEdit={isEdit}>
            <DataSelectList
              name="customerId"
              target="customer"
              value={inputForm.customerId}
              onChange={onChangeCustomer}
              error={!!errorMessages.customerId}
              fullWidth
            />
            <FormErrorText>{errorMessages.customerId}</FormErrorText>
          </DataGrid>
          <DataGrid title="案件番号" value={form.opportunityNumber} />
          <DataGrid title="ステータス" value={form.opportunityStatusName} isEdit={isEdit} isRequired>
            <DataSelectList
              name="opportunityStatusCode"
              target="codes"
              parameter={{ codeCategory: 'opportunity_status' }}
              value={inputForm.opportunityStatusCode}
              onChange={onChange}
              error={!!errorMessages.opportunityStatusCode}
              fullWidth
              disableClearable
              exclusions={form.opportunityStatusCode === 98 ? [] : [98]}
            />
            <FormErrorText>{errorMessages.opportunityStatusCode}</FormErrorText>
          </DataGrid>
          <DataGrid title="営業担当" value={form.salesUserName} isEdit={isEdit}>
            <DataSelectList
              name="salesUserId"
              target="users"
              parameter={{ userPostCodes: [1] }}
              value={inputForm.salesUserId}
              onChange={onChange}
              error={!!errorMessages.salesUserId}
              fullWidth
            />
          </DataGrid>
          <DataGrid title="注文受付日" value={form.receptionDate} isEdit={isEdit}>
            <DatePicker error={!!errorMessages.receptionDate} value={inputForm.receptionDate} name="receptionDate" onChange={onChange} />
            <FormErrorText>{errorMessages.receptionDate}</FormErrorText>
          </DataGrid>
          <DataGrid title="対応期限日時" value={form.complianceDt} isEdit={isEdit}>
            <DateTimePicker error={!!errorMessages.complianceDt} value={inputForm.complianceDt} name="complianceDt" onChange={onChange} />
            <FormErrorText>{errorMessages.complianceDt}</FormErrorText>
          </DataGrid>
          <DataGrid title="案件概要" isEdit={isEdit} value={form.overview}>
            <TextArea value={inputForm.overview} name="overview" onChange={onChange} />
          </DataGrid>
        </Accordion>

        <Accordion title="注文情報">
          {form.id && (
            <DataGrid title="総合計金額(税込)" value={form.totalPrice} />
          )}
          <DataGrid title="開催日" value={form.heldDate} isEdit={isEdit}>
            <DatePicker error={!!errorMessages.heldDate} value={inputForm.heldDate} name="heldDate" onChange={onChange} />
            <FormErrorText>{errorMessages.heldDate}</FormErrorText>
          </DataGrid>
          <DataGrid title="開催時間" value={form.heldCharTime} isEdit={isEdit}>
            <TextField autoComplete="off" inputProps={{ maxLength: 40 }} value={inputForm.heldCharTime} fullWidth name="heldCharTime" onChange={onChange} />
          </DataGrid>
          <DataGrid title="準備開始可能時間" value={form.cateringPreparationrTime} isEdit={isEdit}>
            <TimePicker value={inputForm.cateringPreparationrTime} name="cateringPreparationrTime" onChange={onChange} error={!!errorMessages.cateringPreparationrTime} />
            <FormErrorText>{errorMessages.cateringPreparationrTime}</FormErrorText>
          </DataGrid>
          <DataGrid title="イベント終宴時間" value={form.cateringEventEndTime} isEdit={isEdit}>
            <TimePicker value={inputForm.cateringEventEndTime} name="cateringEventEndTime" onChange={onChange} error={!!errorMessages.cateringEventEndTime} />
            <FormErrorText>{errorMessages.cateringEventEndTime}</FormErrorText>
          </DataGrid>
          <DataGrid title="イベント撤収完了時間" value={form.cateringCleanUpTime} isEdit={isEdit}>
            <TimePicker value={inputForm.cateringCleanUpTime} name="cateringCleanUpTime" onChange={onChange} error={!!errorMessages.cateringCleanUpTime} />
            <FormErrorText>{errorMessages.cateringCleanUpTime}</FormErrorText>
          </DataGrid>
          <DataGrid title="受注経路" value={form.orderRouteName} isEdit={isEdit}>
            <DataSelectList
              name="orderRouteCode"
              target="codes"
              parameter={{ codeCategory: 'order_route' }}
              value={inputForm.orderRouteCode}
              onChange={onChange}
              error={!!errorMessages.orderRouteCode}
              fullWidth
            />
          </DataGrid>
          <DataGrid title="支払方法" value={form.paymentMethodName} isEdit={isEdit}>
            <DataSelectList
              name="paymentMethodId"
              target="payment-methods"
              value={inputForm.paymentMethodId}
              onChange={onChange}
              error={!!errorMessages.paymentMethodId}
              fullWidth
            />
          </DataGrid>
          <DataGrid title="参加人数" value={form.joinNumber} isEdit={isEdit}>
            <NumberInput value={inputForm.joinNumber} name="joinNumber" onChange={onChange} />
          </DataGrid>
          <DataGrid title="領収書" value={form.receiptRequired ? '要' : ''} isEdit={isEdit}>
            <FormSwitch
              name="receiptRequired"
              checked={inputForm.receiptRequired}
              onChange={onChange}
              label="領収書要"
            />
          </DataGrid>
          <DataGrid title="領収書宛名" value={form.receiptAddress} isEdit={isEdit}>
            <TextField autoComplete="off" inputProps={{ maxLength: 255 }} value={inputForm.receiptAddress} fullWidth name="receiptAddress" onChange={onChange} />
          </DataGrid>
          <DataGrid title="但書注意" value={form.proviso} isEdit={isEdit}>
            <TextField autoComplete="off" inputProps={{ maxLength: 255 }} value={inputForm.proviso} name="proviso" fullWidth onChange={onChange} />
          </DataGrid>
        </Accordion>

        <Accordion title="納品情報">
          <PrefectureInput form={form} inputForm={inputForm} isEdit={isEdit} setForm={setInputForm} addressColumnName="otherAddress" addressColumnLabel="その他住所" />
          <DataGrid title="搬入経路" value={form.deliveryRouteText} isEdit={isEdit}>
            <TextArea value={inputForm.deliveryRouteText} name="deliveryRouteText" fullWidth onChange={onChange} />
          </DataGrid>
        </Accordion>

        <Accordion title="ステータス情報">
          <DataGrid title="お客様に確定メール送付" isEdit={isEdit} value={form.customerSend ? '送付済' : ''}>
            <FormSwitch checked={inputForm.customerSend} onChange={onChange} name="customerSend" label="送付済にする" />
          </DataGrid>
          <DataGrid title="結膳手配有無" isEdit={isEdit} value={form.yuizenArranged ? '手配済' : ''}>
            <FormSwitch checked={inputForm.yuizenArranged} onChange={onChange} name="yuizenArranged" label="手配済にする" />
          </DataGrid>
        </Accordion>

        <Accordion title="お客様名情報">
          <FormHelperText sx={{ marginBottom: 2 }}>
            こちらの情報が入力された場合、会員情報が設定されていても、こちらの情報を使用します。
          </FormHelperText>
          <DataGrid title="提携企業確認済" value={form.affiliationChecked ? '済' : ''} isEdit={isEdit}>
            <FormSwitch
              name="affiliationChecked"
              checked={inputForm.affiliationChecked}
              onChange={onChange}
              label="提携企業確認済"
            />
          </DataGrid>
          <DataGrid title="会員確認済" value={form.customerChecked ? '済' : ''} isEdit={isEdit}>
            <FormSwitch
              name="customerChecked"
              checked={inputForm.customerChecked}
              onChange={onChange}
              label="会員確認済"
            />
          </DataGrid>
          <DataGrid title="提携企業" value={form.affiliationName} isEdit={isEdit}>
            <DataSelectList
              name="affiliationId"
              target="affiliations"
              value={inputForm.affiliationId}
              onChange={onChange}
              fullWidth
            />
          </DataGrid>
          <DataGrid title="所属先会社" value={form.companyName} isEdit={isEdit}>
            <TextField autoComplete="off" inputProps={{ maxLength: 255 }} value={inputForm.companyName} fullWidth name="companyName" onChange={onChange} />
          </DataGrid>
          <DataGrid title="支店営業所" value={form.branchName} isEdit={isEdit}>
            <TextField autoComplete="off" inputProps={{ maxLength: 255 }} value={inputForm.branchName} fullWidth name="branchName" onChange={onChange} />
          </DataGrid>
          <DataGrid title="電話番号" value={form.customerPhone} isEdit={isEdit}>
            <TextField autoComplete="off" inputProps={{ maxLength: 255 }} value={inputForm.customerPhone} fullWidth name="customerPhone" onChange={onChange} />
          </DataGrid>
          <DataGrid title="メール" value={form.customerEmail} isEdit={isEdit}>
            <TextField autoComplete="off" inputProps={{ maxLength: 255 }} value={inputForm.customerEmail} error={!!errorMessages.customerEmail} fullWidth name="customerEmail" onChange={onChange} />
            <FormErrorText>{errorMessages.customerEmail}</FormErrorText>
          </DataGrid>
        </Accordion>
        {form.id && (
          <Accordion title="会員情報">
            <DataGrid title="所属先会社" value={form.companyNameFromCustomer} />
            <DataGrid title="支店営業所" value={form.branchNameFromCustomer} />
            <DataGrid title="メール" value={form.customerEmailFromCustomer} />
            <DataGrid title="携帯電話" value={form.customerMobilePhoneFromCustomer} />
            <DataGrid title="固定電話" value={form.customerFixedPhoneFromCustomer} />
          </Accordion>
        )}
        <Accordion title="その他情報">
          <DataGrid title="PO番号" value={form.poNumber} isEdit={isEdit}>
            <TextArea value={inputForm.poNumber} name="poNumber" onChange={onChange} maxLength={200} />
          </DataGrid>
          <DataGrid title="代行案件数" isEdit={isEdit} value={form.agencyNumber}>
            <NumberInput value={inputForm.agencyNumber} name="agencyNumber" onChange={onChange} />
          </DataGrid>
          <DataGrid title="結膳店舗数" isEdit={isEdit} value={form.yuizenShopNumber}>
            <NumberInput value={inputForm.yuizenShopNumber} name="yuizenShopNumber" onChange={onChange} />
          </DataGrid>
        </Accordion>

        <Accordion title="備考情報">
          <DataGrid title="注文状態詳細" isEdit={isEdit} value={form.orderRemarks}>
            <TextArea value={inputForm.orderRemarks} name="orderRemarks" onChange={onChange} />
          </DataGrid>
          <DataGrid title="社内用備考" isEdit={isEdit} value={form.insideRemarks}>
            <TextArea value={inputForm.insideRemarks} name="insideRemarks" onChange={onChange} />
          </DataGrid>
          <DataGrid title="店舗向け備考" isEdit={isEdit} value={form.shopRemarks}>
            <TextArea value={inputForm.shopRemarks} name="shopRemarks" onChange={onChange} />
          </DataGrid>
          <DataGrid title="商品内容備考" isEdit={isEdit} value={form.productRemarks}>
            <TextArea value={inputForm.productRemarks} name="productRemarks" onChange={onChange} />
          </DataGrid>
          <DataGrid title="ユーザー向け備考" isEdit={isEdit} value={form.customerRemarks}>
            <TextArea value={inputForm.customerRemarks} name="customerRemarks" onChange={onChange} />
          </DataGrid>
          <DataGrid title="顧客リクエスト" isEdit={isEdit} value={form.customerRequest}>
            <TextArea value={inputForm.customerRequest} name="customerRequest" onChange={onChange} />
          </DataGrid>
        </Accordion>
        <Accordion title="その他">
          <DataGrid title="ポイント付与済" value={form.pointGranted ? '済' : ''} isEdit={isEdit}>
            <FormSwitch
              name="pointGranted"
              checked={inputForm.pointGranted}
              onChange={onChange}
              label="ポイント付与済"
            />
          </DataGrid>
          <DataGrid title="付与ポイント数" value={`${form.grantedPoint}pt`} />
          <DataGrid title="口コミ受付" value={form.reviewTargeted ? '受付ける' : ''} isEdit={isEdit}>
            <FormSwitch
              name="reviewTargeted"
              checked={inputForm.reviewTargeted}
              onChange={onChange}
              label="受付ける"
            />
          </DataGrid>
          <DataGrid title="実例対象" value={form.illustrationTargeted ? '対象' : ''} isEdit={isEdit}>
            <FormSwitch
              name="illustrationTargeted"
              checked={inputForm.illustrationTargeted}
              onChange={onChange}
              label="対象"
            />
          </DataGrid>
        </Accordion>
        {!isEdit && form.id && (
          <Box mt={2}><SystemInfo data={form} /></Box>
        )}
        {isEdit && (
        <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
          {id && (
          <Button color="inherit" variant="contained" size="small" onClick={onEditCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
          )}
          <Button variant="contained" size="small" onClick={onSave}>保存</Button>
        </Box>
        )}
      </RecordBox>
      <AffiliationConfirmDialog
        isOpen={openAffiliation}
        onClose={() => setOpenAffiliation(false)}
        data={data}
      />
      <CustomerConfirmDialog
        isOpen={openCustomer}
        onClose={() => setOpenCustomer(false)}
        data={data}
      />
    </>
  );
}
