import { createSlice } from '@reduxjs/toolkit';

export const planSlice = createSlice({
  name: 'plan',
  initialState: {
    datas: {},
    searchCondition: null,
    isSaveSuccess: false,
    isDeleteSuccess: false,
  },
  reducers: {
    setCreateSuccess: (state) => {
      state.isCreateSuccess = true;
    },
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCsvDatas: () => {},
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    resetFlg: (state) => {
      state.isSaveSuccess = false;
      state.isCreateSuccess = false;
      state.isDeleteSuccess = false;
      state.saveId = null;
    },
  },
});

export const planActions = planSlice.actions;

export default planSlice.reducer;
