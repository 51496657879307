const ROOT_URL = 'https://api.catering-selection.jp';

export const REST_URL = `${ROOT_URL}/admin`;

export const REST_API = {
  COMMON: {
    LOGIN: `${REST_URL}/authenticate`,
    LOGIN_INFO: `${REST_URL}/login-info`,
    POINT_RULE: `${REST_URL}/common/point-rules`,
    RESET_PASSWORD: `${REST_URL}/reset-password`,
  },
  SELECT: {
    CODE: `${REST_URL}/select/codes`,
    AREA: `${REST_URL}/select/areas`,
  },
  CONDITION: {
    CONDITION: `${ROOT_URL}/common/conditions`,
    CONDITION_ID: `${ROOT_URL}/common/conditions/{id}`,
  },
  HOME: {
    TODO: `${REST_URL}/todos`,
    OPPORTUNITY_STATUS: `${REST_URL}/opportunity-statuses`,
  },
  OPPORTUNITY: {
    CONTACT: {
      CONTACT: `${REST_URL}/contacts`,
      CONTACT_CSV: `${REST_URL}/contacts/csv`,
      CONTACT_ID: `${REST_URL}/contacts/{id}`,
      CONTACT_OPPORTUNITY: `${REST_URL}/contacts/opportunities`,
    },
    OPPORTUNITY: {
      OPPORTUNITY: `${REST_URL}/opportunities`,
      OPPORTUNITY_CALENDAR: `${REST_URL}/opportunities/calendar`,
      OPPORTUNITY_CSV: `${REST_URL}/opportunities/csv`,
      OPPORTUNITY_POINT: `${REST_URL}/opportunities/points`,
      OPPORTUNITY_ID: `${REST_URL}/opportunities/{id}`,
      OPPORTUNITY_AFFILIATION: `${REST_URL}/opportunities/affiliations/{id}`,
      OPPORTUNITY_CUSTOMER: `${REST_URL}/opportunities/customers/{id}`,
      OPPORTUNITY_CANCEL: `${REST_URL}/opportunities/cancel`,
      OPPORTUNITY_SEND_ORDER: `${REST_URL}/opportunities/send-orders`,
      OPPORTUNITY_CLAIM: `${REST_URL}/opportunities/claim`,
      OPPORTUNITY_CLAIM_FROM_LIST: `${REST_URL}/opportunities/claims`,
      OPPORTUNITY_FILE: `${REST_URL}/opportunities/files`,
      OPPORTUNITY_FILE_ID: `${REST_URL}/opportunities/files/{id}`,
      CANCEL_PRICE: `${REST_URL}/opportunities/cancel-price`,
    },
    OPPORTUNITY_ATTACH: {
      CANCEL: `${REST_URL}/opportunities/cancel-rates`,
      CANCEL_ID: `${REST_URL}/opportunities/cancel-rates/{id}`,
      HISTORY: `${REST_URL}/opportunities/status-histories`,
      DELIVERY: `${REST_URL}/opportunities/delivery-files`,
      DELIVERY_ID: `${REST_URL}/opportunities/delivery-files/{id}`,
    },
    REPORT: {
      ORDER: `${REST_URL}/opportunities/reports/orders/{id}`,
      CLAIM: `${REST_URL}/opportunities/reports/claims/{id}`,
      ESTIMATE: `${REST_URL}/opportunities/reports/estimates/{id}`,
      PURCHASE_ORDER: `${REST_URL}/opportunities/reports/purchase-orders/{id}`,
      RECEIPT: `${REST_URL}/opportunities/reports/receipts/{id}`,
    },
    FILE: {
      FILE: `${REST_URL}/opportunities/file`,
      FILES: `${REST_URL}/opportunities/files`,
    },
    ESTIMATE: {
      ESTIMATE: `${REST_URL}/estimates`,
      ESTIMATE_CSV: `${REST_URL}/estimates/csv`,
      ESTIMATE_ID: `${REST_URL}/estimates/{id}`,
      ESTIMATE_DETAIL: `${REST_URL}/estimate-details`,
      ESTIMATE_DETAIL_ID: `${REST_URL}/estimate-details/{id}`,
      ESTIMATE_DETAIL_BULK: `${REST_URL}/estimate-details/bulk`,
      ESTIMATE_HISTORY: `${REST_URL}/estimates-output-histories`,
      ESTIMATE_HISTORY_ID: `${REST_URL}/estimates-output-histories/{id}`,
      ESTIMATE_SEND_QUOTATION: `${REST_URL}/estimates/send-quotations`,
      ESTIMATE_ORDER: `${REST_URL}/estimates/orders/{id}`,
    },
    ORDER: {
      ORDER: `${REST_URL}/orders`,
      ORDER_CSV: `${REST_URL}/orders/csv`,
      ORDER_ID: `${REST_URL}/orders/{id}`,
      ORDER_DETAIL: `${REST_URL}/order-details`,
      ORDER_DETAIL_ID: `${REST_URL}/order-details/{id}`,
      ORDER_REQUEST: `${REST_URL}/orders/request`,
      ORDER_SEND_PURCHASE_ORDER: `${REST_URL}/orders/send-purchase-orders`,
      ORDER_SEND_TEXT: `${REST_URL}/orders/send-text`,
    },
    CLAIM: {
      CLAIM: `${REST_URL}/claims`,
      CLAIM_CSV: `${REST_URL}/claims/csv`,
      CLAIM_ID: `${REST_URL}/claims/{id}`,
      CLAIM_DETAIL: `${REST_URL}/claim-details`,
      CLAIM_DETAIL_ID: `${REST_URL}/claim-details/{id}`,
      CLAIM_SEND_FILES: `${REST_URL}/claims/send-files`,
      CLAIM_PAYMENT_LINK: `${REST_URL}/claims/payment-link`,
      CLAIM_SEND_TEXT: `${REST_URL}/claims/send-text`,
    },
    CLAIM_ATTACH: {
      ORDER_RELATION: `${REST_URL}/claims/order-relations`,
      ORDER_RELATION_ID: `${REST_URL}/claims/order-relations/{id}`,
      FILES: `${REST_URL}/claims/files`,
    },
    CUSTOMER: {
      CUSTOMER: `${REST_URL}/customers`,
      CUSTOMER_CSV: `${REST_URL}/customers/csv`,
      CUSTOMER_POINT: `${REST_URL}/customers/points`,
      CUSTOMER_ID: `${REST_URL}/customers/{id}`,
      POINT: {
        POINT_HISTORY: `${REST_URL}/customers/point-histories`,
        POINT_HISTORY_ID: `${REST_URL}/customers/point-histories/{id}`,
      },
      VIEW_FAVORITE: {
        FAVORITE: `${REST_URL}/customers/favorites`,
        VIEW_HISTORY: `${REST_URL}/customers/view-histories`,
      },
    },
  },
  SHOP: {
    ACCOUNT: {
      ACCOUNT: `${REST_URL}/accounts`,
      ACCOUNT_CSV: `${REST_URL}/accounts/csv`,
      ACCOUNT_ID: `${REST_URL}/accounts/{id}`,
    },
    ACCOUNT_USER: {
      ACCOUNT_USER: `${REST_URL}/accounts/users`,
      ACCOUNT_USER_ID: `${REST_URL}/accounts/users/{id}`,
    },
    SHOP: {
      SHOP: `${REST_URL}/shops`,
      SHOP_CSV: `${REST_URL}/shops/csv`,
      SHOP_ID: `${REST_URL}/shops/{id}`,
    },
    SHOP_ATTACHED: {
      OPTION: `${REST_URL}/shops/options`,
      OPTION_ID: `${REST_URL}/shops/options/{id}`,
      HOLIDAY: `${REST_URL}/shops/holidays`,
      HOLIDAY_ID: `${REST_URL}/shops/holidays/{id}`,
      DELIVERY: `${REST_URL}/shops/deliveries`,
      DELIVERY_ID: `${REST_URL}/shops/deliveries/{id}`,
      CANCEL_RATE: `${REST_URL}/shops/cancel-rates`,
      CANCEL_RATE_ID: `${REST_URL}/shops/cancel-rates/{id}`,
    },
    SHOP_NOTICE: {
      SHOP_NOTICE: `${REST_URL}/shop-notices`,
      CATEGORIES: `${REST_URL}/shop-notices/categories`,
      SHOP_NOTICE_CSV: `${REST_URL}/shop-notices/csv`,
      SHOP_NOTICE_ID: `${REST_URL}/shop-notices/{id}`,
    },
    PLAN: {
      PLAN: `${REST_URL}/plans`,
      PLAN_CSV: `${REST_URL}/plans/csv`,
      PLAN_ID: `${REST_URL}/plans/{id}`,
    },
    PLAN_ATTACHED: {
      OPTION: `${REST_URL}/plans/options`,
      OPTION_ID: `${REST_URL}/plans/options/{id}`,
    },
    VENDOR: {
      VENDOR: `${REST_URL}/vendors`,
      VENDOR_CSV: `${REST_URL}/vendors/csv`,
      VENDOR_ID: `${REST_URL}/vendors/{id}`,
    },
    DELIVERY_AGENCY: {
      DELIVERY_AGENCY: `${REST_URL}/delivery-agencies`,
      DELIVERY_AGENCY_CSV: `${REST_URL}/delivery-agencies/csv`,
      DELIVERY_AGENCY_ID: `${REST_URL}/delivery-agencies/{id}`,
    },
    DELIVERY_AGENCY_USER: {
      DELIVERY_AGENCY_USER: `${REST_URL}/delivery-agencies/users`,
      DELIVERY_AGENCY_USER_ID: `${REST_URL}/delivery-agencies/users/{id}`,
    },
  },
  PAGE_SETTING: {
    REVIEW: {
      REVIEW: `${REST_URL}/reviews`,
      REVIEW_CSV: `${REST_URL}/reviews/csv`,
      REVIEW_ID: `${REST_URL}/reviews/{id}`,
      REVIEW_STATUS: `${REST_URL}/reviews/status`,
    },
    ILLUSTRATION: {
      ILLUSTRATION: `${REST_URL}/illustrations`,
      ILLUSTRATION_CSV: `${REST_URL}/illustrations/csv`,
      ILLUSTRATION_ID: `${REST_URL}/illustrations/{id}`,
    },
    SPECIAL_FEATURE: {
      SPECIAL_FEATURE: `${REST_URL}/special-features`,
      SPECIAL_FEATURE_CSV: `${REST_URL}/special-features/csv`,
      SPECIAL_FEATURE_ID: `${REST_URL}/special-features/{id}`,
    },
    SPECIAL_FEATURE_ATTACHED: {
      SPECIAL_FEATURE_CONTENT: `${REST_URL}/special-features/contents`,
      SPECIAL_FEATURE_CONTENT_ID: `${REST_URL}/special-features/contents/{id}`,
      SPECIAL_FEATURE_SHOP: `${REST_URL}/special-features/shops`,
      SPECIAL_FEATURE_SHOP_ID: `${REST_URL}/special-features/shops/{id}`,
      SPECIAL_FEATURE_PLAN: `${REST_URL}/special-features/plans`,
      SPECIAL_FEATURE_PLAN_ID: `${REST_URL}/special-features/plans/{id}`,
      SPECIAL_FEATURE_AREA: `${REST_URL}/special-features/areas`,
    },
    BLOG: {
      BLOG: `${REST_URL}/blogs`,
      BLOG_ID: `${REST_URL}/blogs/{id}`,
      BLOG_CSV: `${REST_URL}/blogs/csv`,
    },
    BLOG_CONTENT: {
      BLOG_CONTENT: `${REST_URL}/blog-contents`,
      BLOG_CONTENT_ID: `${REST_URL}/blog-contents/{id}`,
    },
  },
  SYSTEM_SETTING: {
    ROLE: {
      ROLE: `${REST_URL}/roles`,
      ROLE_CSV: `${REST_URL}/roles/csv`,
      ROLE_SCREEN: `${REST_URL}/roles/screens`,
      ROLE_ID: `${REST_URL}/roles/{id}`,
      ROLE_COPY: `${REST_URL}/roles/copy`,
    },
    LOGIN_USER: {
      LOGIN_USER: `${REST_URL}/login-users`,
      LOGIN_USER_CSV: `${REST_URL}/login-users/csv`,
      LOGIN_USER_CHANGE_PASSWORD: `${REST_URL}/login-users/change-password`,
      LOGIN_USER_ID: `${REST_URL}/login-users/{id}`,
    },
    POINT_RULE: {
      POINT_RULE: `${REST_URL}/point-rules`,
      POINT_RULE_CSV: `${REST_URL}/point-rules/csv`,
      POINT_RULE_ID: `${REST_URL}/point-rules/{id}`,
    },
    MAIL_TEMPLATE: {
      MAIL_TEMPLATE: `${REST_URL}/mail-templates`,
      MAIL_TEMPLATE_CSV: `${REST_URL}/mail-templates/csv`,
      FOLDERS: `${REST_URL}/mail-templates/folders`,
      MAIL_TEMPLATE_ID: `${REST_URL}/mail-templates/{id}`,
    },
    TRASH: {
      TRASH: `${REST_URL}/trash`,
    },
  },
};
