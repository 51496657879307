import {
  Box, Button,
} from '@mui/material';
import React, {
  useEffect, useRef, useState,
} from 'react';
import {
  LinkBubbleMenu,
  RichTextEditor,
  RichTextReadOnly,
  TableBubbleMenu,
} from 'mui-tiptap';
import EditorMenuControls from './EditorMenuControls';
import useExtensions from './useExtensions';

export default function Editor(props) {
  const {
    onChange, content, isEdit, title, name,
  } = props;
  const extensions = useExtensions({
    placeholder: `${title}詳細を入力...`,
  });
  const rteRef = useRef(null);
  const [submittedContent, setSubmittedContent] = useState('');
  const [isPreview, setPreview] = useState(false);

  useEffect(() => {
    if (content && isEdit) {
      rteRef.current.editor.commands.setContent(content);
    }
  }, [isEdit]);

  return (
    <>
      {!isPreview && (
        <Box
          sx={{
            '& .ProseMirror': {
              '& h1, & h2, & h3, & h4, & h5, & h6': {
                scrollMarginTop: 50,
              },
            },
          }}
        >
          <RichTextEditor
            onBlur={() => {
              onChange({
                target: {
                  name,
                  value: rteRef.current?.editor?.getHTML() ?? '',
                },
              });
            }}
            ref={rteRef}
            extensions={extensions}
            content={content}
            editable
            renderControls={() => <EditorMenuControls />}
            RichTextFieldProps={{
              variant: 'outlined',
              MenuBarProps: {
                hide: false,
              },
              footer: (
                <Box
                  spacing={2}
                  sx={{
                    borderTopStyle: 'solid',
                    borderTopWidth: 1,
                    borderTopColor: (theme) => theme.palette.divider,
                    py: 1,
                    px: 1.5,
                    textAlign: 'right',
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setSubmittedContent(
                        rteRef.current?.editor?.getHTML() ?? '',
                      );
                      setPreview(true);
                    }}
                  >
                    プレビュー
                  </Button>
                </Box>
              ),
            }}
          >
            {() => (
              <>
                <LinkBubbleMenu />
                <TableBubbleMenu />
              </>
            )}
          </RichTextEditor>
        </Box>
      )}

      {isPreview && (
        <Box mt={3} sx={{ border: 'rgba(0, 0, 0, 0.12) 1px solid', borderRadius: '4px' }}>
          <Box p={3}>
            <RichTextReadOnly
              content={submittedContent}
              extensions={extensions}
            />
          </Box>
          <Box
            spacing={2}
            sx={{
              borderTopStyle: 'solid',
              borderTopWidth: 1,
              borderTopColor: (theme) => theme.palette.divider,
              py: 1,
              px: 1.5,
              textAlign: 'right',
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setPreview(false);
              }}
            >
              編集
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
