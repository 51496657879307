import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, TextField, FormHelperText,
} from '@mui/material';
import SelectStoreComponent from '../common/selectStoreComponent/SelectStoreComponent';
import CommonValidation from '../../../utils/common.validation';
import { emptyItemsOtherSelectedStore } from '../../../utils/common.helper';
import DataGrid from '../../../components/templates/DataGrid';
import { orderActions } from '../../../redux/opportunity/order/orderListState';
import Validation from './validation';
import SelectOpportunityDialog from '../common/SelectOpportunityDialog/SelectOpportunityDialog';
import detailUrl from '../../../constants/frontUrls';
import {
  Link, TimePicker, TextArea, FormErrorText, RecordBox, SearchSelectBox, FormSwitch,
} from '../../../components/atoms/Base';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import NumberInput from '../../../components/atoms/NumberInput';
import ServiceType from '../../../components/atoms/ServiceType';
import SystemInfo from '../../../components/templates/SystemInfo';
import Accordion from '../../../components/templates/Accordion';
import { RecordHeaderButton } from '../../../components/templates/RecordHeader';

const baseForm = {
  id: null,
  shopId: null,
  vendorId: null,
  deliveryAgencyId: null,
  serviceTypeCode: 1,
  salesforceSend: false,
  shopSend: false,
  usedPoint: 0,
};

export default function OrderDetailInfo(props) {
  const {
    id, data, isDisabled,
  } = props;

  const dispatch = useDispatch();
  const [order, setOrder] = useState({});
  const [inputOrder, setInputOrder] = useState(baseForm);
  const [detailType, setDetailType] = useState(1);
  const [isEdit, setEdit] = useState(true);
  const [isSelectOpen, setSelectOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const isSaveSuccess = useSelector((state) => state.orderStore.isSaveSuccess);

  React.useEffect(() => {
    if (id) {
      setEdit(false);
    }
  }, [id]);

  React.useEffect(() => {
    if (data) {
      setOrder(data);
      setInputOrder(data);
      if (data.vendorId) {
        setDetailType(2);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      setEdit(false);
    }
  });

  const onEdit = () => {
    dispatch(orderActions.getData({ id: order.id }));
    setEdit(true);
    setInputOrder(baseForm);
    setErrorMessages({});
  };

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(inputOrder).forEach((key) => {
      const msg = Validation.formValidate(key, inputOrder[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
      const result = CommonValidation.storeValidate(inputOrder, tempMessage, detailType);
      if (result.isError) {
        isError = true;
        tempMessage = result.tempMessage;
      }
    });
    if (isError) {
      setErrorMessages(tempMessage);
    }
    return isError;
  };

  const onSave = () => {
    let tempInputOrder = emptyItemsOtherSelectedStore(inputOrder, detailType);
    if (order.affiliationId) {
      tempInputOrder = { ...tempInputOrder, usedPoint: 0 };
    }
    if (beforeSaveCheck()) {
      return;
    }
    dispatch(orderActions.saveData(tempInputOrder));
  };

  const onEditCancel = () => {
    setEdit(false);
    setInputOrder(order);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const updatedOrder = {
      ...inputOrder,
      [name]: value,
    };
    const tempErrorMessages = {
      ...errorMessages,
      [name]: Validation.formValidate(name, value),
    };
    setInputOrder(updatedOrder);
    setErrorMessages(tempErrorMessages);
  };

  const onSelect = (_, selected) => {
    const updatedOrder = {
      ...inputOrder,
      opportunityId: selected.id,
      opportunityNumber: selected.opportunityNumber,
    };
    const tempErrorMessages = {
      ...errorMessages,
      opportunityId: Validation.formValidate('opportunityId', selected.id),
    };
    setSelectOpen(false);
    setInputOrder(updatedOrder);
    setErrorMessages(tempErrorMessages);
  };

  return (
    <>
      <RecordHeaderButton
        edit={isEdit}
        disabled={isDisabled}
        onEdit={onEdit}
      />
      <RecordBox>
        <Accordion title="基本情報">
          {id && (
            <>
              <DataGrid title="注文番号" value={order.orderNumber} />
              <DataGrid title="発注番号" value={order.purchaseOrderNumber} />
            </>
          )}
          <DataGrid title="ステータス" isRequired isEdit={isEdit} value={order.orderStatusName}>
            <DataSelectList
              error={!!errorMessages.orderStatusCode}
              name="orderStatusCode"
              target="codes"
              parameter={{ codeCategory: 'order_status' }}
              value={inputOrder.orderStatusCode}
              onChange={onChange}
              disableClearable
            />
            <FormErrorText>{errorMessages.orderStatusCode}</FormErrorText>
          </DataGrid>
          <DataGrid title="サービスタイプ" isRequired isEdit={isEdit} value={order.serviceTypeName}>
            <ServiceType
              error={errorMessages.serviceTypeCode}
              value={inputOrder.serviceTypeCode}
              onChange={onChange}
            />
            <FormErrorText>{errorMessages.serviceTypeCode}</FormErrorText>
          </DataGrid>
          <SelectStoreComponent
            isEdit={isEdit}
            onChange={onChange}
            setDetailType={setDetailType}
            row={inputOrder}
            detailType={detailType}
            errorMessage={errorMessages}
          />
          <DataGrid
            title="案件番号"
            value={(
              <Link to={detailUrl.OPPORTUNITY_DETAIL + order.opportunityId}>
                {order.opportunityNumber}
              </Link>
            )}
            isRequired
            isEdit={!id}
          >
            <Box>{inputOrder.opportunityNumber}</Box>
            <Box mt={2}>
              <Button size="small" variant="contained" onClick={() => setSelectOpen(true)}>案件選択</Button>
            </Box>
            <FormErrorText>{errorMessages.opportunityId}</FormErrorText>
          </DataGrid>
          <DataGrid
            title="代行会社名"
            isEdit={isEdit}
            value={(
              <Link to={detailUrl.DELIVERY_AGENCY_DETAIL + order.deliveryAgencyId}>
                {order.deliveryAgencyName}
              </Link>
            )}
          >
            <DataSelectList
              name="deliveryAgencyId"
              target="delivery-agencies"
              value={inputOrder.deliveryAgencyId}
              onChange={onChange}
            />
          </DataGrid>
        </Accordion>

        <Accordion title="金額">
          <DataGrid title="ポイント利用額" isEdit={isEdit} value={order.usedPoint || 0}>
            <NumberInput name="usedPoint" onChange={onChange} value={inputOrder.usedPoint} error={!!errorMessages.usedPoint} fullWidth />
          </DataGrid>
          {order.affiliationId && (
            <FormHelperText>提携企業の場合は注文にポイントはご利用できません。</FormHelperText>
          )}
        </Accordion>

        {id && (
          <>
            <Accordion title="金額明細">
              <DataGrid title="8%小計(税込)" value={order.eightPercentTotalPrice} />
              <DataGrid title="8%消費税" value={order.eightPercentTotalTaxPrice} />
              <DataGrid title="8%割引額" value={order.eightPercentDiscountPrice} />
              <DataGrid title="10%小計(税込)" value={order.tenPercentTotalPrice} />
              <DataGrid title="10%消費税" value={order.tenPercentTotalTaxPrice} />
              <DataGrid title="10%割引額" value={order.tenPercentDiscountPrice} />
            </Accordion>
            <Accordion title="合計金額">
              <DataGrid title="合計金額(税込)" value={order.totalPrice} />
              <DataGrid title="顧客請求額(税込)" value={order.customerBillPrice} />
              <DataGrid title="販売手数料請求額(税抜)" value={order.salesFeeBillPrice} />
              <DataGrid title="返金対象額(税込)" value={order.refundPrice} />
              <DataGrid title="手数料(税込)" value={order.commissionPrice} />
              <DataGrid title="仕入原価合計(税込)" value={order.purchasingPrice} />
              <DataGrid title="利益額(税込)" value={order.benefitPrice} />
            </Accordion>
          </>
        )}
        <Accordion title="店舗依頼">
          <DataGrid title="ボール" isEdit={isEdit} value={order.ball}>
            <SearchSelectBox
              name="ball"
              options={[{ id: 'cqree', name: 'cqree' }, { id: '店舗', name: '店舗' }]}
              value={inputOrder.ball}
              onChange={onChange}
            />
          </DataGrid>
          <DataGrid title="店舗への依頼内容" isEdit={isEdit} value={order.requestDetail}>
            <TextArea name="requestDetail" value={inputOrder.requestDetail} onChange={onChange} />
          </DataGrid>
        </Accordion>
        <Accordion title="ドライバー情報">
          <DataGrid title="ドライバー名" isEdit={isEdit} value={order.driverName}>
            <TextField value={inputOrder.driverName} fullWidth name="driverName" onChange={onChange} inputProps={{ maxLength: 50 }} />
          </DataGrid>
          <DataGrid title="ドライバー電話番号" isEdit={isEdit} value={order.driverPhone}>
            <TextField value={inputOrder.driverPhone} fullWidth name="driverPhone" onChange={onChange} inputProps={{ maxLength: 15 }} />
            <FormErrorText>{errorMessages.driverPhone}</FormErrorText>
          </DataGrid>
          <DataGrid title="ドライバーピックアップ時間" isEdit={isEdit} value={order.driverPickupTime}>
            <TimePicker value={inputOrder.driverPickupTime} name="driverPickupTime" onChange={onChange} fullWidth />
            <FormErrorText>{errorMessages.driverPickupTime}</FormErrorText>
          </DataGrid>
        </Accordion>
        <Accordion title="店舗入力">
          <DataGrid title="店舗使用用備考" isEdit={isEdit} value={order.shopRemarks} />
          <DataGrid title="店舗受注可否" isEdit={isEdit} value={(order.shopPropriety === 1 && '可') || (order.shopPropriety === 0 && '否')}>
            <SearchSelectBox
              name="shopPropriety"
              options={[{ id: '1', name: '可' }, { id: '0', name: '否' }]}
              value={String(inputOrder.shopPropriety)}
              onChange={onChange}
            />
          </DataGrid>
          <DataGrid title="店舗受注可否コメント" isEdit={isEdit} value={order.shopProprietyRemarks}>
            <TextArea value={inputOrder.shopProprietyRemarks} name="shopProprietyRemarks" fullWidth onChange={onChange} />
          </DataGrid>
        </Accordion>
        <Accordion title="その他">
          <DataGrid title="決済日" value={order.paymentDate} />
          <DataGrid title="店舗に確定メール送付済" isEdit={isEdit} value={order.shopSend ? '送付済' : ''}>
            <FormSwitch checked={inputOrder.shopSend} onChange={onChange} label="送付済にする" name="shopSend" />
          </DataGrid>
          <DataGrid title="Salesforce連携済" isEdit={isEdit} value={order.salesforceSend ? '連携済' : ''}>
            <FormSwitch checked={inputOrder.salesforceSend} onChange={onChange} label="連携済にする" name="salesforceSend" />
          </DataGrid>
        </Accordion>

        {isEdit && (
          <Box position="sticky" bottom={0} left={0} zIndex={3} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
            {id && <Button color="inherit" variant="contained" size="small" onClick={onEditCancel} style={{ marginRight: '10px' }}>キャンセル</Button>}
            <Button variant="contained" size="small" onClick={onSave}>保存</Button>
          </Box>
        )}
        {!isEdit && order.id && (
          <Box mt={2}><SystemInfo data={order} /></Box>
        )}
      </RecordBox>
      <SelectOpportunityDialog
        isOpen={isSelectOpen}
        onSelect={onSelect}
        onClose={() => setSelectOpen(false)}
      />
    </>
  );
}
