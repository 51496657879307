import React from 'react';
import { connect } from 'react-redux';
import {
  Button, Box, Grid,
} from '@mui/material';
import { contactDetailActions } from '../../../redux/opportunity/contact/contactDetailState';
import {
  SuccessSnackbar, RecordBox, withParams, FormErrorText, Link, DateTimePicker,
  FireIconTitle, TextArea, TabPanel,
} from '../../../components/atoms/Base';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import DataGrid from '../../../components/templates/DataGrid';
import CreateOpportunityDialog from './CreateOpportunityDialog';
import detailUrl from '../../../constants/frontUrls';
import Validation from './validation';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import SystemInfo from '../../../components/templates/SystemInfo';
import Accordion from '../../../components/templates/Accordion';
import Tabs from '../../../components/templates/Tabs';
import RecordHeader, { RecordHeaderButton } from '../../../components/templates/RecordHeader';

class ContactDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    const form = {
      id,
      contactCategoryCode: '',
      contactCategoryName: '',
      contactStatusCode: '',
      contactStatusName: '',
      shopId: '',
      shopName: '',
      planId: '',
      planName: '',
      companyName: '',
      customerName: '',
      customerPhone: '',
      customerEmail: '',
      content: '',
      remarks: '',
      salesUserId: '',
      salesUserName: '',
      fireComplianceDt: '',
      complianceDt: '',
      opportunityId: '',
      opportunityNumber: '',
      createdByName: '',
      createdAt: '',
      updatedByName: '',
      updatedAt: '',
    };

    let isSuccessOpen = false;
    if (props.contactDetailInfo.isSaveSuccess) {
      isSuccessOpen = true;
      props.dispatch(contactDetailActions.resetFlg());
    }
    this.state = {
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isSuccessOpen,
      form,
      inputForm: form,
      errorMessages: {
        contactStatusCode: '',
        complianceDt: '',
      },
      isEdit: !form.id,
      isCreateOppOpen: false,
    };
    if (id) {
      props.dispatch(contactDetailActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contactDetailInfo.data !== this.props.contactDetailInfo.data) {
      if (this.props.contactDetailInfo.data) {
        this.setData(this.props.contactDetailInfo.data);
      }
    }
    if (prevProps.contactDetailInfo.isSaveSuccess !== this.props.contactDetailInfo.isSaveSuccess) {
      if (this.props.contactDetailInfo.isSaveSuccess) {
        this.setSuccess(this.props.contactDetailInfo.saveId);
      }
    }
  }

  setData = (form) => {
    this.setState({ form, inputForm: form });
  };

  onCloseSuccessbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  setSuccess = (id) => {
    const { form } = this.state;
    const searchId = id || form.id;
    if (form.id) {
      this.props.dispatch(contactDetailActions.resetFlg());
    }
    if (!form.id) {
      window.location.href = detailUrl.CONTACT_DETAIL + searchId;
      return;
    }
    this.setState({ isEdit: false, isSuccessOpen: true });
    this.props.dispatch(contactDetailActions.getData({ id: searchId }));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;
    const changeTempForm = {
      ...inputForm,
      [name]: value,
    };
    this.setState({
      inputForm: changeTempForm,
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  onEdit = () => {
    const { form } = this.state;
    const tempErrorMessages = {
      complianceDt: '',
    };
    this.setState({ isEdit: true, errorMessages: tempErrorMessages });
    this.props.dispatch(contactDetailActions.getData({ id: form.id }));
  };

  onEditCancel = () => {
    this.setState({ isEdit: false });
  };

  onSave = () => {
    const { inputForm, errorMessages } = this.state;
    if (this.beforeSaveCheck(inputForm, errorMessages)) {
      return;
    }
    this.props.dispatch(contactDetailActions.saveData(inputForm));
  };

  onCreateOppModalOpen = () => {
    this.setState({ isCreateOppOpen: true });
  };

  onCreateOppModalClose = () => {
    this.setState({ isCreateOppOpen: false });
  };

  beforeSaveCheck = (form, errorMessages) => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
    return isError;
  };

  render() {
    const {
      isDisabled,
      errorMessages,
      form,
      isSuccessOpen,
      isEdit,
      isCreateOppOpen,
      inputForm,
    } = this.state;

    return (
      <>
        {!isEdit && !isDisabled && !form.opportunityId && (
          <RecordHeader
            bottomSection={(
              <>
                <Box mr={1}><FireIconTitle checkDateTime={form.fireComplianceDt} isDispText /></Box>
                <Button color="primary" variant="contained" size="small" onClick={this.onCreateOppModalOpen}>案件作成</Button>
              </>
            )}
          />
        )}
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={6}>
            <RecordHeaderButton
              edit={isEdit}
              disabled={isDisabled}
              onEdit={this.onEdit}
            />
            <RecordBox>
              <DataGrid title="ステータス" isRequired isEdit={isEdit} value={form.contactStatusName}>
                <DataSelectList
                  name="contactStatusCode"
                  target="codes"
                  parameter={{ codeCategory: 'contact_status' }}
                  value={inputForm.contactStatusCode}
                  onChange={this.onChange}
                  error={!!errorMessages.contactStatusCode}
                />
                <FormErrorText>{errorMessages.contactStatusCode}</FormErrorText>
              </DataGrid>
              <DataGrid title="メモ" isEdit={isEdit} value={form.remarks}>
                <TextArea value={inputForm.remarks} name="remarks" onChange={this.onChange} />
              </DataGrid>
              <DataGrid title="問合せ対応担当者" isEdit={isEdit} value={form.salesUserName}>
                <DataSelectList
                  name="salesUserId"
                  target="users"
                  value={inputForm.salesUserId}
                  onChange={this.onChange}
                />
              </DataGrid>
              <DataGrid title="対応期限日時" isEdit={isEdit} value={form.complianceDt}>
                <DateTimePicker value={inputForm.complianceDt} name="complianceDt" onChange={this.onChange} />
                <FormErrorText>{errorMessages.complianceDt}</FormErrorText>
              </DataGrid>
              <DataGrid title="案件" value={<Link to={detailUrl.OPPORTUNITY_DETAIL + form.opportunityId}>{form.opportunityNumber}</Link>} />
              {isEdit && (
                <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
                  {form.id && (
                    <Button color="inherit" size="small" variant="contained" onClick={this.onEditCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
                  )}
                  <Button color="primary" size="small" variant="contained" onClick={this.onSave}>保存</Button>
                </Box>
              )}
              {!isEdit && form.id && (
                <SystemInfo data={form} />
              )}
            </RecordBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <Tabs
              value={0}
              tabs={['問合せ詳細']}
            />
            <TabPanel value={0} index={0}>
              <RecordBox>
                <Accordion title="基本情報">
                  <DataGrid title="会社名" value={form.companyName} />
                  <DataGrid title="お客様名" value={form.customerName} />
                  <DataGrid title="電話番号" value={form.customerPhone} />
                  <DataGrid title="メールアドレス" value={form.customerEmail} />
                  <DataGrid title="問合せ種別" value={form.contactCategoryName} />
                  {form.contactCategoryCode !== 1 && (
                    <>
                      <DataGrid
                        title="問合せ店舗"
                        value={(
                          <Link to={detailUrl.SHOP_DETAIL + form.shopId}>
                            {form.shopName}
                          </Link>
                        )}
                      />
                      <DataGrid
                        title="問合せプラン"
                        value={(
                          <Link to={detailUrl.PLAN_DETAIL + form.planId}>
                            {form.planName}
                          </Link>
                        )}
                      />
                    </>
                  )}
                  {(form.contactCategoryCode !== 1) && (
                    <DataGrid title="問合せ内容" value={form.content} />
                  )}
                  {form.contactCategoryCode === 1 && (
                    <DataGrid title="ご注文種別" value={form.orderType} />
                  )}
                </Accordion>
                {(form.contactCategoryCode === 1
                && (form.orderType === 'ケータリング(スタッフのサービス付)' || form.orderType === 'オードブル(お料理のお届けのみ)'))
                && (
                  <>
                    <Accordion title="日時">
                      <DataGrid title="開催予定日" value={form.scheduleDateText} />
                      <DataGrid title="利用シーン" value={form.useSceneType} />
                      {form.orderType === 'ケータリング(スタッフのサービス付)' && (
                        <>
                          <DataGrid title="イベント開演時間" value={form.deliveryTime} />
                          <DataGrid title="準備開始可能時間（搬入時間）" value={form.readyStartTime} />
                          <DataGrid title="イベント終演時間" value={form.eventEndTime} />
                          <DataGrid title="撤収完了時間" value={form.eventWithdrawalTime} />
                        </>
                      )}
                      {form.orderType === 'オードブル(お料理のお届けのみ)' && (
                        <DataGrid title="お届け時間" value={form.deliveryTime} />
                      )}
                    </Accordion>
                    <Accordion title="参加者情報">
                      <DataGrid title="会場(お届け場所)の住所" value={form.venueAddress} />
                      <DataGrid title="ご予算" value={form.budget} />
                      <DataGrid title="参加人数" value={form.attendanceNumber} />
                      {form.orderType === 'ケータリング(スタッフのサービス付)' && (
                        <DataGrid title="主な参加者の年代" value={form.mainAge} />
                      )}
                    </Accordion>
                    {form.orderType === 'ケータリング(スタッフのサービス付)' && (
                      <Accordion title="イベントの形式">
                        <DataGrid title="形式" value={form.eventType} />
                        <DataGrid title="お料理のご希望" value={form.foodHope} />
                        <DataGrid title="テーブルクロスを使用する対象" value={form.tableClothTarget} />
                        <DataGrid title="ドリンク種類" value={form.drinkType} />
                        <DataGrid title="ドリンク提供方法" value={form.offerType} />
                        <DataGrid title="カトラリー(ナイフ・フォーク・スプーン)" value={form.cutleryType} />
                        <DataGrid title="取り皿" value={form.plateType} />
                        <DataGrid title="飲み物グラス" value={form.drinkGlassType} />
                        <DataGrid title="開演中のスタッフ常駐" value={form.staffStayType} />
                        <DataGrid title="装飾のご希望" value={form.decorationHope} />
                        <DataGrid title="会場の準備" value={form.venueReserve} />
                        <DataGrid title="おおよその会場の広さ" value={form.venueExtent} />
                        <DataGrid title="利用できるテーブル" value={form.useTable} />
                        <DataGrid title="火器使用" value={form.useFireType} />
                        <DataGrid title="電気設備" value={form.useElectricityType} />
                        <DataGrid title="バックヤード(台車やスタッフ荷物置き場)" value={form.backyardType} />
                        <DataGrid title="パントリー(流し場)" value={form.pantryType} />
                        <DataGrid title="スタッフによる会場設営" value={form.venueSetupStaffType} />
                      </Accordion>
                    )}
                    <Accordion title="搬入について">
                      <DataGrid title="台車の利用" value={form.trolleyType} />
                      <DataGrid title="搬入車駐車場" value={form.parkingType} />
                      <DataGrid title="搬入事前申請" value={form.carryRequestType} />
                      <DataGrid title="搬入についての制限" value={form.carryLimit} />
                      <DataGrid title="お支払い" value={form.paymentMethodType} />
                    </Accordion>
                  </>
                )}
              </RecordBox>
            </TabPanel>
          </Grid>
        </Grid>
        <CreateOpportunityDialog
          isOpen={isCreateOppOpen}
          onClose={this.onCreateOppModalClose}
          form={form}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSuccessbar}
          message="保存しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  contactDetailInfo: state.contactDetailStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(ContactDetail));
